import '@wdpr/activity-indicator';
import '@wdpr/card';
import '@wdpr/status-label';
import '@wdpr/single-select';
import '@wdpr/button';
import '@wdpr/modal';
import '@wdpr/breadcrumbs';
import '@wdpr/pocket-panel';
import '@wdpr/kingswell-header';
import '@wdpr/informational-message';
import '@wdpr/input';
import '@wdpr/error-panel';
import '@wdpr/tabs';
import '@wdpr/multi-select';
import '@wdpr/toast';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivityIndicatorService } from '@app/core/services/activity-indicator/activity-indicator.service';
import { GlobalHeaderComponent } from '@core/components/global-header/global-header.component';
import { BreadcrumbsComponent } from '@core/components/breadcrumbs/breadcrumbs.component';
import { Router } from '@angular/router';

/**
 * RA Components Library: https://raportal.wdprapps.disney.com/web-components-catalog/kingswell
 */
@Component({
  selector: 'app-kingswell-shell',
  templateUrl: './kingswell-shell.component.html',
  styleUrls: ['./kingswell-shell.component.scss']
})
export class KingswellShellComponent implements OnInit, AfterViewInit, OnDestroy {

  title = 'Travel Trade Connect Admin';

  @ViewChild('header') _header: GlobalHeaderComponent;

  @ViewChild('breadcrumbs') _breadcrumbs: BreadcrumbsComponent;

  @ViewChild('WdprActivityModal') _activityModal: ElementRef;
  showActivityIndicator = false;
  activityIndicatorReason = "";

  private _subscriptions: Subscription[] = [];

  constructor(
    private activityIndicatorService: ActivityIndicatorService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.activityIndicatorSubscriber();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit() {
    // If by some chance, someone is capable of routing to the shell itself... just route to home
    if (this.router.url === '/web') {
      this.router.navigate(['/web/home']);
    }
  }

  activityIndicatorSubscriber() {

    const activityIndicatorSubscription = this.activityIndicatorService.showActivityIndicator$.subscribe((value) => {
      this.showActivityIndicator = value;
      this.changeDetector.detectChanges();
    });

    const activityIndicatorReasonSubscription = this.activityIndicatorService.activityIndicatorReason$.subscribe((value) => {
      this.activityIndicatorReason = value;
      this.changeDetector.detectChanges();
    });

    this._subscriptions.push(activityIndicatorSubscription);
    this._subscriptions.push(activityIndicatorReasonSubscription);

  }

}
