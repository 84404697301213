import { Component, Input } from '@angular/core';
import { CardGridConfig } from '@interfaces/card-grid-config';

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss']
})
export class CardGridComponent {

  @Input() config: CardGridConfig[];

}
