import { ChannelManager } from '@interfaces/ttc/channel-managers';
import { withCache } from '@ngneat/cashew';
import { ConfigService } from '@services/config/config.service';
import { Distributor } from '@interfaces/distributor';
import { DistributorsRequest } from '@interfaces/ttc/distributors-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Observable } from 'rxjs';
import { OrphanTransaction } from '@interfaces/orphan-transaction';
import { StopSellBodyRequest } from '@interfaces/ttc/stop-sell-body-request';
import { StopSellSelection } from '@interfaces/stop-sell-selection';
import { StopSellType } from '@services/trade-utility/stop-sell-type';
import { StopsellTask } from '@interfaces/stopsell-task';
import { StopsellTaskRequest } from '@interfaces/ttc/stopsell-task-request';

@Injectable({
  providedIn: 'root'
})
export class TradeUtilityService {

  private configs: object;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private logger: LoggerService
  ) {
    this.configs = this.configService.get();
  }

  public getChannelManagers(): Observable<ChannelManager[]> {

    return this.httpClient.get<[]>(
      `${this.configs['WEB_API_BASE_URL']}/channel-managers`,
      {
        context: withCache({
          version: 'v1',
          key: 'channelManagers'
        })
      }
    );

  }

  public getDistributors(request: DistributorsRequest): Observable<Distributor[]>  {

    return this.httpClient.get<[]>(
      `${this.configs['WEB_API_BASE_URL']}/distributors`,
      {
        headers: request.headers,
        params: request.params,
        context: withCache({
          version: 'v1',
          key: 'distributors' + request.headers.propertyBrand + request.headers.requestorId
        })
      }
    );

  }

  public getStopsellStatus(request: StopsellTaskRequest): Observable<StopsellTask[]>  {

    return this.httpClient.get<[]>(
      `${this.configs['WEB_API_BASE_URL']}/stop-sell`,
      {
        headers: request.headers,
        params: request.params,
      }
    );

  }

  public getOrphanTransactions(): Observable<OrphanTransaction[]> {

    return this.httpClient.get<[]>(
      `${this.configs['WEB_API_BASE_URL']}/admin/dti-transaction`
    );

  }

  public updateOrphanTransactions(request: OrphanTransaction[]) {

    return this.httpClient.post<[]>(
      `${this.configs['WEB_API_BASE_URL']}/dti-transactions`,
        request
    );
  }

  public updateStopSell(
    selectedItems: StopSellSelection[],
    channelManager: string,
    destination: string,
    type: StopSellType | string,
    stopSellActive: boolean,
    reason: string,
    values: string[] = []
  ) {

    const options = {
      headers: {
        requestorid: channelManager,
        propertybrand: destination
      },
      params: {}
    };

    const bodyRequest: StopSellBodyRequest = {
      type,
      values,
      active: stopSellActive.toString(),
      reason,
      stopSellSelections: selectedItems
    };

    return this.httpClient.put<[]>(
      `${this.configs['WEB_API_BASE_URL']}/stop-sell`,
      bodyRequest,
      options
    );

  }

}
