<div class="content-wrapper">
  <div class="features-wrapper">
    <h4>Usage Overview</h4>
    <dl>
      <dt>Navigation</dt>
      <dd>Application navigation is managed via the <i class="di di-menu-global-nav"></i> hamburger menu</dd>
      <dt>Login</dt>
      <dd>In most instances you will be redirected to MyID for login</dd>
      <dt>Logout</dt>
      <dd>You can click your user-badge in the top-right to logout</dd>
    </dl>
  </div>
  <div class="features-wrapper">
    <h4>Features</h4>
    <dl>
      <dt><i class="di di-restriction"></i>Stopsell</dt>
      <dd>Enable/Disable the ability for product to be sold</dd>
      <dt><i class="di di-will-call-tickets"></i>Manage Distributors</dt>
      <dd>Perform bulk modification of distributors</dd>
      <dt><i class="di di-mockey-food-seal"></i>Validations</dt>
      <dd>Perform system tests for ingested content</dd>
    </dl>
  </div>
  <div class="support-wrapper">
    <h4>Support</h4>
    <dl>
      <dt><i class="di di-website-support"></i>Documentation</dt>
      <dd>Review / Read the documentation for the admin console</dd>
      <dt><i class="di di-chat"></i>Ask a question</dt>
      <dd>Reach out to our team on Slack</dd>
      <dt><i class="di di-security-info"></i>Request access</dt>
      <dd>Open a ticket for access</dd>
    </dl>
  </div>
</div>
