import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { StopsellStatusComponent } from '@core/components/stopsell-status/stopsell-status.component';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { AccordionModule } from 'primeng/accordion';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CorrelationModule } from '@wdpr/ra-angular-correlation';
import { DividerModule } from 'primeng/divider';
import { EnvironmentSwitcherComponent } from './components/environment-switcher/environment-switcher.component';
import { ErrorHandlerModule } from '@wdpr/ra-angular-error-handler';
import { FileUploadInputComponent } from "@core/components/file-input/file-upload-input.component";
import { FlatDataTableComponent } from './components/flat-data-table/flat-data-table.component';
import { FormsModule } from "@angular/forms";
import { GlobalHeaderComponent } from './components/global-header/global-header.component';
import { HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KingswellShellComponent } from './components/kingswell-shell/kingswell-shell.component';
import { LoggerModule } from '@wdpr/ra-angular-logger';
import { OidcRedirectorComponent } from '@core/components/oidc-redirector/oidc-redirector.component';
import { PanelNavComponent } from '@core/components/panel-nav/panel-nav.component';
import { RippleModule } from 'primeng/ripple';
import { StopsellHistoryComponent } from './components/stopsell-history/stopsell-history.component';
import { StopsellPocketPanelComponent } from './components/stopsell-pocket-panel/stopsell-pocket-panel.component';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TreeTableModule } from 'primeng/treetable';
import { ErrorCharacterModalComponent } from './components/error-character-modal/error-character-modal.component';
import { MyIdSessionModalComponent } from '@core/components/myid-session-modal/myid-session-modal.component';
import { CalendarModule } from "primeng/calendar";
import { CardGridComponent } from './components/card-grid/card-grid.component';


/**
 * Modules act as IOC containers and we specify providers which
 * are effectively factories that help to inject objects into our components.
 */
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    HttpClientModule,
    HttpCacheInterceptorModule.forRoot(),
    CorrelationModule,
    LoggerModule,
    ErrorHandlerModule,
    RouterOutlet,
    NgClass,
    RouterLink,
    RouterLinkActive,
    TableModule,
    NgForOf,
    NgIf,
    AsyncPipe,
    BrowserAnimationsModule,
    ButtonModule,
    TreeTableModule,
    FormsModule,
    AccordionModule,
    CardModule,
    DividerModule,
    FormsModule,
    RippleModule,
    InputTextareaModule,
    InputTextModule,
    TagModule,
    CalendarModule,
    ProgressBarModule,
    BlockUIModule
  ],
  providers: [
    // LocalStorage Cache (nice, but requires quite a bit more setup for invalidation and such)
    //useHttpCacheLocalStorage
  ],
  exports: [
    FlatDataTableComponent,
    OidcRedirectorComponent,
    PanelNavComponent,
    KingswellShellComponent,
    FileUploadInputComponent,
    StopsellPocketPanelComponent,
    BreadcrumbsComponent,
    GlobalHeaderComponent,
    EnvironmentSwitcherComponent,
    StopsellHistoryComponent,
    ErrorCharacterModalComponent,
    MyIdSessionModalComponent,
    StopsellStatusComponent,
    CardGridComponent
  ],
  /**
   * Declare every component that we are using which will be auto-wired when it's picked up in a template.
   */
  declarations: [
    OidcRedirectorComponent,
    PanelNavComponent,
    KingswellShellComponent,
    FileUploadInputComponent,
    StopsellPocketPanelComponent,
    BreadcrumbsComponent,
    GlobalHeaderComponent,
    EnvironmentSwitcherComponent,
    FlatDataTableComponent,
    StopsellHistoryComponent,
    ErrorCharacterModalComponent,
    MyIdSessionModalComponent,
    StopsellStatusComponent,
    CardGridComponent
  ]
})
export class CoreModule { }
