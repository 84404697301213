<div class="validations-main">
  <wdpr-tabs #validationTabs selected-tab-index="0">

    <wdpr-tab id="tab-product-validation" role="tab">
      Validate - Products
    </wdpr-tab>

    <wdpr-tab id="tab-product-reports" role="tab">
      Reports
    </wdpr-tab>

    <div slot="body">

      <div data-tab-index="0" tabindex="0" labelledby="tab-product-validation" class="page-main">
        <div class="validation-options">
          <app-file-upload-input
            #productListUpload
            id="productListUpload"
            (uploadFile)="onLoadValidationFile($event)"
            (clear)="onClear($event)"
            [fileExtensions]="fileExtentions"
          />
          <wdpr-button
            #runJob
            (click)="onRunJob($event)" theme="secondary"
            [attr.disabled]="!productData ? (true) : (null)"
          >
            <i class="di di-media-play-button"></i>Run All
          </wdpr-button>
        </div>
      </div>

      <div data-tab-index="2" tabindex="2" labelledby="tab-product-reports" class="page-main">
        <div class="grid-wrapper">
          <ul>
            <li>No Reports Found</li>
          </ul>
        </div>
      </div>

    </div>

  </wdpr-tabs>
</div>
