<wdpr-tabs selected-tab-index="0">

  <!-- Selectable Tabs -->
  <wdpr-tab id="tab-a0">{{title}}</wdpr-tab>
  <wdpr-tab id="tab-a1">History</wdpr-tab>

  <div slot="body" id="tabbed-outlet">

    <!-- Stopsell Content -->
    <div data-tab-index="0" tabindex="0" class="stopsell-main">

      <app-stopsell-status [targetElement]="main_content" [stopsellType]="stopsellType"></app-stopsell-status>

      <p-panel #main_content [style]="{ width: '100%', 'border-top': '1px solid #dee2e6' }" [showHeader]="false">
        <div class="header-wrapper">

          <div class="cm-wrapper">
            <wdpr-single-select
              #cmSelectInput
              label="Channel Manager"
              required-field="true"
              [attr.disabled]="channelManagerSelectOptions.length === 0 ? true : null"
              [attr.options]="JSON.stringify(channelManagerSelectOptions)"
              (item-selected)="onSelectChannelManager($event)"
            ></wdpr-single-select>
          </div>

          <div class="dest-wrapper">
            <wdpr-single-select
              #destinationSelectInput
              label="Destination"
              required-field="true"
              [attr.disabled]="destinationSelectOptions.length === 0 ? true : null"
              [attr.options]="JSON.stringify(destinationSelectOptions)"
              (item-selected)="onSelectDestination($event)"
            ></wdpr-single-select>
          </div>

          <div class="submit-stopsell-wrapper">
            <wdpr-button
              #stopsellToggleButton
              [ngClass]="{'enableStopsell': (enableStopsell && remarksEntered.length > 0) }"
              [attr.disabled]="remarksEntered.length === 0 ? true : null"
              (click)="onStopsellToggleClicked()"
            >
              Toggle Stopsell
            </wdpr-button>
          </div>

          <div class="remark-wrapper">
            <span class="p-float-label">
              <textarea
                #remarksInput
                id="remarks-input"
                rows="4"
                pInputTextarea
                [disabled]="destSelected.length === 0"
                [(ngModel)]="remarksEntered"
              ></textarea>
              <label for="remarks-input">Remarks</label>
            </span>
          </div>

        </div>

        <div class="grid-wrapper">
          <app-flat-data-table
            #dataTable
            [data]="datum"
            [config]="dataTableConfig"
            (dataLoaded)="onDataTableLoaded()"
          ></app-flat-data-table>
        </div>
      </p-panel>

    </div>

    <!-- Stopsell History Tab -->
    <div data-tab-index="1" tabindex="1" class="stopsell-history">
      <div slot="body" class="history-wrapper">
        <app-stopsell-history #stopsellHistory [type]="stopsellType"/>
      </div>
    </div>

  </div>

</wdpr-tabs>

<app-stopsell-pocket-panel
  #PocketPanelComponent
  [title]="pocketPanelTitle"
  (saveStopSell)="onToggleStopsell()" [pocketPanelBody]="pocketPanelBodyStopsell">
</app-stopsell-pocket-panel>
