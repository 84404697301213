import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FlatDataTable } from '@interfaces/flat-data-table';
import { DataTableRow } from '@interfaces/data-table-row';

@Component({
  selector: 'app-flat-data-table',
  templateUrl: './flat-data-table.component.html',
  styleUrls: ['./flat-data-table.component.scss']
})
export class FlatDataTableComponent implements OnChanges {

  /**
   * Visual configuration for the data-table and the assortment of available options.
   */
  @Input() config: FlatDataTable;

  /**
   * Table Data; needs to be a promise to support lazy loading.
   */
  @Input() data: Promise<DataTableRow[]> = Promise.resolve([]);
  protected tableData: DataTableRow[] = [];
  protected tableSelectedData: DataTableRow[] = [];

  /**
   * Output event, contains all rows selected; if selection is disabled, all rows are selected by default.
   */
  @Output() selectedData = new EventEmitter();

  @Output() dataLoaded = new EventEmitter();

  @Output() refreshClicked = new EventEmitter();

  ngOnChanges(changes: SimpleChanges | object): void {

    if(changes['data']) {

      this.data?.then((newData) => {
        this.tableData = newData;
        this.tableSelectedData = [];
        this.dataLoaded.emit();
      });

    }

  }

  calculateScrollSize(): number {
    if (this.config.size === 'sm') {
      return 39;
    } else if (this.config.size === 'md') {
      return 46;
    } else if (this.config.size === 'lg') {
      return 52;
    } else {
      return 39;
    }
  }

  /**
   * Safely returns selected data-table data.
   */
  getSelectedData() {
    return this.tableSelectedData ? (this.tableSelectedData) : ([]);
  }

  /**
   * Safely returns the current data set on the data-table.
   */
  getTableData() {
    return this.tableData ? (this.tableData) : ([]);
  }

  setTableData(datum: DataTableRow[]) {
    this.tableData = datum ? datum : [];
  }

  /**
   * Selects all rows on the data-table.
   */
  selectAllRows() {
    this.tableSelectedData = this.tableData;
  }

  /**
   * Will pre-select a row depending on matching condition.
   * @param predicate
   */
  selectRowOnCondition(predicate: (item: DataTableRow) => boolean) {
    const foundData = this.tableData.filter(predicate);
    this.tableSelectedData = foundData;
  }

  /**
   * If row data is selected will emit event notifying subscribers to perform an action.
   */
  selectionChanged() {
    this.selectedData.emit(this.getSelectedData());
  }

}
