<div class="table-wrapper" *ngIf="config">
  <p-table
    #dataTable
    [value]="data | async"
    [dataKey]="config.primaryKey"
    [columns]="config.columns"
    [editMode]="config.enableEditableRows ? ('row') : (undefined)"
    styleClass="
      p-datatable-{{config.size ? (config.size) : ('sm')}}
      {{config.displayGrid ? ('p-datatable-gridlines') : ('')}}
    "
    [(selection)]="tableSelectedData"
    (selectionChange)="selectionChanged()"
    rows="15"
    [scrollable]="config.enableLazyScroll"
    [scrollHeight]="'800px'"
    [autoLayout]="false"
  >
    <!------------------- [Table Caption] -------------------->
    <ng-template [ngIf]="config.enableQuickFilter" [ngIfElse]="noCaption">
      <ng-template pTemplate="caption">
        <div class="table-filter-wrapper">
          <span class="p-input-icon-left table-filter">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dataTable.filterGlobal($event.target['value'], 'contains')" placeholder="Search keyword" />
          </span>
          <span class="metrics">
            <span class="selected-count">Selected: {{tableSelectedData.length}} / {{tableData.length}}</span>
          </span>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #noCaption></ng-template>

    <!------------------- [Table Header] -------------------->
    <ng-template #tableColumns pTemplate="header" let-columns>
      <tr>

        <th *ngIf="config.enableSelection" style="padding-left: 1.0rem; width: 3.25rem">
          <p-tableHeaderCheckbox [disabled]="config.lockManualSelection"></p-tableHeaderCheckbox>
        </th>

        <th *ngIf="config.enableEditableRows">Modify</th>

        <!-- Render out columns -->
        <ng-container *ngFor="let column of columns">

          <!-- If sortable option, make sortable -->
          <th *ngIf="column['sortable']" pSortableColumn="{{column['field']}}">
            {{column['label']}}
            <p-sortIcon field="{{column['field']}}"></p-sortIcon>
          </th>

          <!-- If not sortable just render as text -->
          <th *ngIf="!column['sortable']">{{column['label']}}</th>

        </ng-container>

      </tr>
    </ng-template>

    <!------------------- [Table Body] -------------------->
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr style="height: {{calculateScrollSize()}}px">

        <td class="selection-checkbox" *ngIf="config.enableSelection">
          <p-tableCheckbox [disabled]="config.lockManualSelection" style="padding-left: 0.5rem; width: 2rem" [value]="rowData"></p-tableCheckbox>
        </td>

        <td *ngIf="config.enableEditableRows"></td>

        <!-- Render out table body -->
        <ng-container *ngFor="let column of columns">
          <td class="col-type-{{column.type}}">

            <ng-container *ngIf="!column.type || column.type === 'text'">
              <p>{{rowData[column.field]}}</p>
            </ng-container>

            <ng-container *ngIf="column.type === 'status'">
              <p-tag [value]="rowData[column.field]" [severity]="rowData[column.field] ? ('danger') : ('success')"></p-tag>
            </ng-container>

            <ng-container *ngIf="column.type === 'boolean'">
              <p-tag [value]="rowData[column.field]" [severity]="rowData[column.field] ? ('success') : ('danger')"></p-tag>
            </ng-container>

          </td>
        </ng-container>

      </tr>
    </ng-template>

  </p-table>
</div>
