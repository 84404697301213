import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TradeUtilityService } from '@services/trade-utility/trade-utility.service';
import { firstValueFrom } from 'rxjs';
import { ChannelManager } from '@interfaces/ttc/channel-managers';
import { StopSellType } from '@services/trade-utility/stop-sell-type';

import { DistributorsRequest } from '@interfaces/ttc/distributors-request';
import { Distributor } from '@interfaces/distributor';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { FlatDataTable } from '@interfaces/flat-data-table';
import { DataTableRow } from '@interfaces/data-table-row';
import { StopSellDetails } from '@interfaces/stopsell-details';
import { getValueOrDefault } from '@app/utilities/get-value-or-default';
import { StopsellPocketPanelComponent } from '@core/components/stopsell-pocket-panel/stopsell-pocket-panel.component';

@Component({
  selector: 'app-stopsell-global',
  templateUrl: './stopsell-global-page.component.html',
  styleUrls: ['./stopsell-global-page.component.scss']
})
export class StopsellGlobalPageComponent implements OnInit {

  title = "Stopsell - Global";

  stopsellResponseData: any;

  distributorList: Distributor[] = [];

  readonly JSON = JSON;
  readonly stopsellType = StopSellType.GLOBAL;

  @ViewChild('stopsellToggleButton') stopsellToggleButton: ElementRef;
  enableStopsell = false;

  @ViewChild('cmSelectInput') cmSelectInput : ElementRef;
  channelManagerSelected: string;
  channelManagerSelectOptions: string[] = [];
  channelManagers: ChannelManager[] = [];

  @ViewChild('destinationSelectInput') destinationSelectInput: ElementRef;
  destinationSelectOptions: string[] = [];
  destData: string[];
  destSelected = "";

  @ViewChild('remarksInput') remarksInput: ElementRef;
  remarksEntered = "";

  @ViewChild('dataTable') dataTable: FlatDataTableComponent;
  dataTableConfig: FlatDataTable;
  datum: Promise<DataTableRow[]>;
  rawTableDatum: any[] = [];

  @ViewChild('PocketPanelComponent') stopsellPocketPanelComponent: StopsellPocketPanelComponent;
  pocketPanelBodyStopsell: string[] = [];
  pocketPanelTitle = 'Global Stopsell';

  constructor(
    private tus: TradeUtilityService,
    private activityService: ActivityIndicatorService,
    private logger: LoggerService
  ) {
  }

  ngOnInit(): void {

    this.populateChannelManagers();
    this.initializeDataTable();

  }

  private initializeDataTable() {
    this.dataTableConfig = {
      columns: [
        {
          label: 'On StopSell',
          field: 'stopsellStatus',
          type: 'status'
        },
        {
          label: 'Distributor Code',
          field: 'code',
          sortable: true
        },
        {
          label: 'Name',
          field: 'name',
          sortable: true
        },
        {
          label: 'Contract Active',
          field: 'active',
          type: 'boolean'
        }
      ],
      displayGrid: false,
      enableEditableRows: false,
      enableLazyScroll: true,
      enableQuickFilter: true,
      enableSelection: true,
      lockManualSelection: true,
      primaryKey: 'rowId',
      size: 'sm'
    };
  }

  private populateDataTable() {
    this.datum = Promise.resolve(this.rawTableDatum);
  }

  private populateChannelManagers() {

    this.activityService.show("Loading Channel Managers...");

    firstValueFrom(this.tus.getChannelManagers()).then((cmResponse) => {

      this.channelManagers = cmResponse;
      this.channelManagerSelectOptions = this.channelManagers.map(item => item.name);

    }).catch(reason => {
      this.logger.error('', { contextMsg: 'Failed to retrieve channel managers', reason });
    }).finally(() => {
      this.activityService.hide();
    });

  }

  private populateDestinations() {

    const cm = this.channelManagers.find((cm) => {
      return cm.name === this.channelManagerSelected;
    });

    if(cm) {
      this.destinationSelectOptions = cm.propertyBrands.map((propertyBrand) => {
        return propertyBrand.code;
      });
    }

  }

  private populateDistributors() {

    // Call TUS to populate the distributors list
    const request: DistributorsRequest = {
      headers: {
        'X-Fresh-Data': 'false',
        requestorType: 'TTCSpa',
        requestorId: this.channelManagerSelected,
        propertyBrand: this.destSelected
      },
      params: {
        field: 'pos'
      }
    };

    this.activityService.show("Loading Distributors...");

    firstValueFrom( this.tus.getDistributors(request))
      .then(response => {
        this.distributorList = response;

        this.enableStopsell = this.determineStopSellToggleState(this.distributorList, this.destSelected);

        this.rawTableDatum = response.map((item, index) => {

          const rowItem: DataTableRow = {
            rowId: index,
            stopsellStatus: !this.enableStopsell,
            name: item.name.value,
            code: item.name.code,
            active: item.active
          };

          return rowItem;

        });

      }).catch((reason) => {
        this.logger.error('', { contextMsg: 'Failed to retrieve distributors for global stop-sell', reason });
      }).finally(() => {
        this.populateDataTable();
        this.activityService.hide();
      });

  }

  /**
   * Determines if a Distributor in the collection "has" a global stop-sell entry, this look-up short-circuits on first entry found.
   * @param distributors
   * @param selectedDestination
   * @private
   */
  private determineStopSellToggleState(distributors: Distributor[], selectedDestination: string) {

    // Look for the first instance of a distributor that has stop-sell information, if a global was performed it should exist
    const distributorWithGlobalStopsell = distributors.find((distributor) => {
      const pos = getValueOrDefault(selectedDestination, {}, distributor?.propertyBrands)?.pos;
      if(pos) {
        return pos.length > 0
          && pos[0].stopSells?.find((stopsell: StopSellDetails) => stopsell.type.includes('Global'));
      } else {
        return false;
      }
    });

    return !distributorWithGlobalStopsell;

  }

  onStopsellToggleClicked() {

    if (this.enableStopsell) {
      this.pocketPanelTitle = `Set ${this.dataTable?.getSelectedData()?.length} distributors on Global Stopsell?`;
      this.pocketPanelBodyStopsell = [
        "Channel Manager : " + this.channelManagerSelected,
        "Destination : " + this.destSelected,
      ];
    } else {
      this.pocketPanelTitle = `Remove ${this.dataTable?.getSelectedData()?.length} distributors from Global Stopsell?`;
      this.pocketPanelBodyStopsell = [
        "Removing : " + this.dataTable.getSelectedData().length + " distributors",
        "Channel Manager : " + this.channelManagerSelected,
        "Destination : " + this.destSelected
      ];
    }

    this.stopsellPocketPanelComponent.openRightPanel();
  }

  onSelectChannelManager($event) {
    this.rawTableDatum = [];
    this.channelManagerSelected = $event.detail.value;
    this.populateDestinations();
    if(this.destSelected.length > 0) {
      this.destSelected = "";
      this.destinationSelectInput.nativeElement.value = "";
      this.remarksEntered = "";
    }
  }

  onSelectDestination($event) {
    this.destSelected = $event.detail.value;
    this.populateDistributors();
  }

  onToggleStopsell() {

    // Map request base on form and table selections
    const selectedBranchDistributors = [];

    this.activityService.show("Performing Stopsell...");

    return firstValueFrom(
      this.tus.updateStopSell(
        selectedBranchDistributors,
        this.channelManagerSelected,
        this.destSelected,
        this.stopsellType,
        this.enableStopsell,
        this.remarksEntered
      )
    ).then((stopsellResponse) => {
      this.stopsellResponseData = stopsellResponse;

      return this.stopsellResponseData;
    }).catch((reason) => {
      this.logger.error('', { contextMsg: 'Failed to perform global stop-sell call', reason });
    }).finally(() => {
      this.activityService.hide();
    });

  }

  onDataTableLoaded() {
    this.dataTable.selectAllRows();
  }

}
