import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorCharacterModalComponent } from '@core/components/error-character-modal/error-character-modal.component';
import { OIDCSession } from '@interfaces/oidc-session';
import { ErrorCharacterType } from '@core/components/error-character-modal/error-character-type';
import { MyIdService } from '@services/myid/myid.service';
import { Router } from '@angular/router';
import { LoggerService } from '@wdpr/ra-angular-logger';

@Component({
  selector: 'app-myid-session-modal',
  templateUrl: './myid-session-modal.component.html',
  styleUrls: ['./myid-session-modal.component.scss']
})
export class MyIdSessionModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild('myIdCharacterError') _characterErrorModal: ErrorCharacterModalComponent;
  @ViewChild('modalLoginButton') _loginButton: ElementRef;

  private _subscriptions: Subscription[] = [];

  constructor(
      private myIdService: MyIdService,
      private router: Router,
      private logger: LoggerService
  ) {
  }

  ngAfterViewInit(): void {
    this.prepareTokenSubscription();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  prepareTokenSubscription() {
    const tokenSubscription = this.myIdService.token$.subscribe((session) => {
      this.checkSessionStatus(session);
    });
    this._subscriptions.push(tokenSubscription);
  }

  doMyIdLogin(): void {
    this.myIdService.logout(true);
    this.router.navigate(['/web/login']).catch((reason) => {
      this.logger.error('', { contextMsg: 'Failed to navigate to login from MyId Session modal.', reason: reason });
    });
  }

  private checkSessionStatus(session: OIDCSession) {

    if(session && session.oidcToken?.expiresAt) {
      const expiresAt = session.oidcToken.expiresAt;
      const now = Date.now();

      if(now > expiresAt) {
        this._characterErrorModal.show(ErrorCharacterType.SESSION_EXPIRED);
        this.logger.log('', { contextMsg: 'Session has expired for user', email: session?.oidcToken?.userDetails?.email });
      }
    }

  }

}
