import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  @ViewChild('WdprBreadcrumbs') _breadcrumbs: ElementRef;
  breadCrumbsLinks: string;

  private _subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.prepareRouteSubscriber();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onBreadcrumbClicked($event) {
    this.router.navigate([$event.detail.url]);
  }

  /**
   * This subscriber allows us to retrieve the data object from our router configuration.
   */
  prepareRouteSubscriber() {
    const routeSubscription = this.activatedRoute.url.subscribe(() => {

      // Get any child-route
      const snapshot = this.activatedRoute.snapshot.firstChild;

      if(snapshot) {
        // We create a clone of the data because for "whatever" reason the data isn't cloned when the router snapshot receives it
        this.updateBreadCrumbs(this.activatedRoute.snapshot.firstChild.params, structuredClone(this.activatedRoute.snapshot.firstChild.data.breadcrumb));
      }

    });
    this._subscriptions.push(routeSubscription);
  }

  /**
   * Callback for the breadcrumb updates with the route subscriber.
   * @param routeParams
   * @param routeBreadcrumbData
   */
  updateBreadCrumbs(routeParams, routeBreadcrumbData) {

    if (routeBreadcrumbData) {
      const mappedCrumbs = routeBreadcrumbData.map(item => {

        if(item.name.startsWith(':')) {
          const lookup = item.name.substring(1);
          const newName = routeParams[lookup];
          item.name = newName;
          item.url.replaceAll(item.name, newName);
        }

        return item;

      });

      this.breadCrumbsLinks = JSON.stringify(mappedCrumbs);

    } else {

      this.breadCrumbsLinks = JSON.stringify({ name: 'Home', url: '/web/home' });

    }
  }

}
