import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { MyIdService } from '@services/myid/myid.service';
import { ConfigService } from '@services/config/config.service';

/**
 * Intercept HTTP requests for Authorization
 */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  private config: ConfigService;
  private authService: MyIdService;

  // Can't just inject any services for this particular class as it'll create a cyclic dependency for anything using the HTTP client
  constructor(
    private injector: Injector
  ) {
  }

  lazyInjectAuthService() {
    // Injection to occur after everything has been bound, let the first few requests just go through; if we are unauthorized we will be
    // redirected anyway off-site.
    try {

      this.config = this.injector.get(ConfigService);
      this.authService = this.injector.get(MyIdService);

      return true;

    } catch (exception) {
      // Suppress, keep going
    }

    return false;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let interceptedRequest: HttpRequest<unknown>;

    if(this.lazyInjectAuthService()) {
      const accessToken = this.authService.getCurrentSession().oidcToken.accessToken;
      if(accessToken.length > 0) {
        interceptedRequest = req.clone({
          headers: req.headers
            .set('Authorization', `BEARER ${accessToken}`)
            .set('X-Property-Brand', this.config.getCurrentEnvironment().propertyBrand)
        });
      }
    }

    if(!interceptedRequest) {
      interceptedRequest = req;
    }

    // send the newly created request
    return next.handle(interceptedRequest)
      .pipe(
        tap((err) => {
          if (err instanceof HttpErrorResponse) {
            // TODO: If we receive an HTTP error, let's redirect them to the unauthorized page and re-login.
          }
        })
      );
  }
}
