import { Component, OnInit } from '@angular/core';
import { CardGridConfig } from '@interfaces/card-grid-config';

@Component({
  selector: 'app-sync-landing-page',
  templateUrl: './sync-landing-page.component.html',
  styleUrls: ['./sync-landing-page.component.scss']
})
export class SyncLandingPageComponent implements OnInit {

  cards: CardGridConfig[] = [];

  ngOnInit(): void {
    this.cards = [
      {
        title: 'Sync Packages',
        icon: 'di-refresh',
        url: '/web/sync/packages',
        desc: 'Perform a data-synchronization between TravelBox & Channel Managers'
      },
    ];
  }

}
