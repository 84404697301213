export const FUNC_ABILITY_CHANNEL_MANAGER_READ = 'channel-manager.read';
export const FUNC_ABILITY_CHANNEL_MANAGER_WRITE = 'channel-manager.write';
export const FUNC_ABILITY_DISTRIBUTOR_READ = 'distributor.read';
export const FUNC_ABILITY_DISTRIBUTOR_WRITE = 'distributor.write';
export const FUNC_ABILITY_PRODUCTS_READ = 'products.read';
export const FUNC_ABILITY_STOPSELL_READ = 'stopsell.read';
export const FUNC_ABILITY_STOPSELL_WRITE = 'stopsell.write';
export const FUNC_ABILITY_VALIDATIONS_READ = 'validations.read';
export const FUNC_ABILITY_VALIDATIONS_WRITE = 'validations.write';
export const FUNC_ABILITY_ORPHAN_TRANSACTIONS_READ = 'orphan-transactions.read';
export const FUNC_ABILITY_ORPHAN_TRANSACTIONS_WRITE = 'orphan-transactions.write';
export const FUNC_ABILITY_COMMUNICATIONS_READ = 'communications.read';
export const FUNC_ABILITY_COMMUNICATIONS_WRITE = 'communications.write';
export const FUNC_ABILITY_CHANNEL_MANAGER_SYNC_READ = 'channelManager-sync.read';
export const FUNC_ABILITY_CHANNEL_MANAGER_SYNC_WRITE = 'channelManager-sync.write';
