import { Component, Input, OnInit } from '@angular/core';
import { StopsellTask } from '@interfaces/stopsell-task';
import { StopsellTaskRequest } from '@app/core/interfaces/ttc/stopsell-task-request';
import { ActivityIndicatorService } from '@app/core/services/activity-indicator/activity-indicator.service';
import { TradeUtilityService } from '@services/trade-utility/trade-utility.service';
import { firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-stopsell-history',
  templateUrl: './stopsell-history.component.html',
  styleUrls: ['./stopsell-history.component.scss']
})
export class StopsellHistoryComponent implements OnInit {

  protected readonly JSON = JSON;

  constructor(
    private tus: TradeUtilityService,
    private activityService: ActivityIndicatorService
  ) {
  }

  ngOnInit(): void {
    this.fetchAllStopsellTaskStatus();
  }

  @Input() type = "";

  protected stopsellTaskList: StopsellTask[] = [];
  protected stopsellTaskCount = 0;
  protected stopsellTaskStatus = [
    {
      value: 'IN_PROGRESS',
      text: 'IN_PROGRESS'
    },
    {
      value: 'INCOMPLETE',
      text: 'INCOMPLETE'
    },
    {
      value: 'FAILED',
      text: 'FAILED'
    },
    {
      value: 'COMPLETED',
      text: 'COMPLETED'
    }
  ];

  protected stopsellTaskStatusSelected = [
    {
      value: 'IN_PROGRESS',
      text: 'IN_PROGRESS'
    },
    {
      value: 'INCOMPLETE',
      text: 'INCOMPLETE'
    },
    {
      value: 'FAILED',
      text: 'FAILED'
    },
    {
      value: 'COMPLETED',
      text: 'COMPLETED'
    }
  ];

  public getStopsellTaskList() {
    return this.stopsellTaskList;
  }

  stopsellStatusBoxPlaceholder($selected): string {
    const options = {
      '0': 'Select Item(s)',
      '1': $selected[0] ? $selected[0].text : '',
      'default': `${$selected.length} Items`
    };

    return (options[$selected.length] || options['default']);
  }

  fetchAllStopsellTaskStatus() {

    const request: StopsellTaskRequest = {
      headers: {
        requestorType: 'TTCSpa',
      },
      params: {
        type: this.type
      }
    };

    this.activityService.show("Loading Stopsell History...");

    return firstValueFrom(this.tus.getStopsellStatus(request))
      .then((stopsellResponse) => {
        this.stopsellTaskList = stopsellResponse;
      }).catch((reason) => {
        console.log(reason);
      }).finally(() => {
        this.stopsellTaskCount = this.stopsellTaskList.length;
        this.activityService.hide();
      });

  }

  getStopsellStatusDetails(event) {

    this.stopsellTaskList = [];

    // Convert taks to array if only one is returned
    const taskList = (event.detail.value.map(v => v.value)).flatMap(((task) =>
      Array.isArray(task) ? task : [task]
    ));

    if (taskList.length == this.stopsellTaskStatus.length) {
      this.fetchAllStopsellTaskStatus();
    } else {
      this.fetchStopSellHistory(taskList);
    }

  }

  fetchStopSellHistory(taskList: string[]) {

    this.stopsellTaskCount = 0;

    return (async () => {

        this.activityService.show("Loading Stopsell History...");

        const requests = taskList.map((stopsellStatus) => {
          const request: StopsellTaskRequest = {
            headers: {
              requestorType: 'TTCSpa'
            },
            params: {
              type: this.type,
              status: stopsellStatus
            }
          };

          return firstValueFrom(this.tus.getStopsellStatus(request)).then((stopSellTask) => {
            this.stopsellTaskList = this.stopsellTaskList.concat(stopSellTask);
          });

        });

        await Promise.allSettled(requests).catch((reasons) => {
          console.log(requests);
        }).finally(() => {
          this.stopsellTaskCount = this.stopsellTaskList.length;
          this.activityService.hide();
        });

    })();

  }

}
