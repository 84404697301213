import { ConfigService } from '@services/config/config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Observable } from 'rxjs';
import { SparkpostTemplateResult } from "@interfaces/sparkpost-template-result";
import { SparkpostTemplate } from "@interfaces/sparkpost-template";
import { SparkpostSubstitutionData } from "@interfaces/sparkpost-substitution-data";
import { SparkpostTransmission } from "@interfaces/sparkpost-transmission";
import { SparkpostPreview } from "@interfaces/sparkpost-preview";
import { TransmissionResult } from "@interfaces/transmission-result";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private configs: object;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private logger: LoggerService
  ) {
    this.configs = this.configService.get();
  }

  public getTemplatesList(): Observable<SparkpostTemplateResult[]> {

    return this.httpClient.get<SparkpostTemplateResult[]>(
      `${this.configs['WEB_API_BASE_URL']}/email-templates`
    );

  }

  public getTemplate(templateId: string): Observable<SparkpostTemplate>  {

    return this.httpClient.get<SparkpostTemplate>(
      `${this.configs['WEB_API_BASE_URL']}/email-templates/${templateId}`);

  }

  public emailPreview(templateId: string, substitutionData: SparkpostSubstitutionData): Observable<SparkpostPreview>  {

    return this.httpClient.post<SparkpostPreview>(
      `${this.configs['WEB_API_BASE_URL']}/email-preview/${templateId}/preview`,
      substitutionData,
      {
        params: { draft: true }
      }
    );

  }

  public emailTransmission(transmission: SparkpostTransmission): Observable<TransmissionResult>  {

    return this.httpClient.post<TransmissionResult>(
      `${this.configs['WEB_API_BASE_URL']}/email-transmissions`,
      transmission);

  }

}
