import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MyIdService } from '@services/myid/myid.service';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Router } from '@angular/router';
import { WindowRefService } from '@services/window-ref/window-ref.service';
import { ConfigService } from '@services/config/config.service';
import { OIDCSession } from '@interfaces/oidc-session';
import { PanelNavComponent } from '@core/components/panel-nav/panel-nav.component';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('WdprHeader') _header: ElementRef;
  userBadge: string;
  profilePanelTitle: string;
  currentProperty: string;

  @ViewChild('WdprInformationalMessage') _informationalMessage: ElementRef;
  showInformationalMessage = true;
  informationalMessage = "You are not currently signed in, access will be limited.";
  informationalLink = 'Click here to login.';

  @ViewChild('leftPanelNavigation') _leftPanelNavigation: PanelNavComponent;

  private _subscriptions: Subscription[] = [];

  constructor(
    private myIdService: MyIdService,
    private config: ConfigService,
    private logger: LoggerService,
    private router: Router,
    private windowRef: WindowRefService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.prepareTokenSubscriber();
    this.prepareEnvironmentDropdown();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  /**
   * Subscribe to JWT token updates with MyID so that we can update with the users personal information.
   */
  prepareTokenSubscriber() {

    // Update shell-details when MyID session is modified
    const tokenSubscription = this.myIdService.token$.subscribe((session) => {
      this.updateUserBadge(session);
    });

    this._subscriptions.push(tokenSubscription);

  }

  prepareEnvironmentDropdown() {
    this.currentProperty = this.config.getCurrentEnvironment().propertyBrand;
  }

  informationalMessageToLogin() {
    this.router.navigate(['/web/login']).catch((reason) => {
      this.logger.error('', { contextMsg: 'Failed to navigate to login from global header.', reason: reason });
    });
  }

  profileNavigateToRostr() {
    if (this.myIdService.getCurrentSession()?.oidcToken?.userDetails.profile.length > 1) {
      this.windowRef.nativeWindow.open(this.myIdService.getCurrentSession().oidcToken.userDetails.profile, '_blank');
    }
  }

  profileNavigateToLogout() {
    this.myIdService.logout();
    this._header.nativeElement.closeProfilePanel();
  }

  closeNavigationMenu() {
    this._header.nativeElement.closeSidebarPanel();
  }

  private updateUserBadge(session: OIDCSession) {
    try {
      const userDetails = session.oidcToken.userDetails;
      if (userDetails && userDetails.givenName || userDetails.familyName) {
        this.userBadge = `${userDetails.givenName.charAt(0)}${userDetails.familyName.charAt(0)}`;
        this.profilePanelTitle = `${userDetails.givenName} ${userDetails.familyName}`;
      } else {
        this.userBadge = undefined;
      }
      this.showInformationalMessage = !session.valid;
      this.changeDetector.markForCheck();
    } catch (reason) {
      this.logger.log('', { contextMsg: 'Error occurred updating user badge', reason });
    }
  }

}
