<div class="stopsell-history-header">

  <div class="type-select-wrapper">
    <wdpr-multi-select
      checkbox="true"
      select-all="true"
      sort-direction="ascending"
      [placeholder]="stopsellStatusBoxPlaceholder"
      [attr.selected]="JSON.stringify(stopsellTaskStatusSelected)"
      [attr.options]="JSON.stringify(stopsellTaskStatus)"
      (selected-changed)="getStopsellStatusDetails($event)"
    >
    </wdpr-multi-select>
  </div>

  <h4 class="count-wrapper">Count: {{stopsellTaskCount}}</h4>

</div>

<div class="accordion-wrapper" *ngFor="let task of stopsellTaskList">

  <p-accordion>
    <p-accordionTab>
      <ng-template pTemplate="header">
        <label  [ngClass]="task.status.value">Stopsell : {{task?.taskId?.value}}</label>
        <label  [ngClass]="task.status.value">Status : {{task?.status?.value}}</label>
        <label  [ngClass]="task.status.value">By : {{task?.personSummary?.email}}</label>
        <label  [ngClass]="task.status.value">Started : {{task?.dateTimeSpan?.start}}</label>
        <label  [ngClass]="task.status.value">Ended : {{task?.dateTimeSpan?.end}}</label>
      </ng-template>
      <div class="code-block">

        {{JSON.stringify(task, null, 4)}}

      </div>
    </p-accordionTab>
  </p-accordion>

</div>
