<wdpr-pocket-panel
  #rightPanel
  disable-swipe="true"
  close-button="true"
>
  <div class="body" slot="body">
    <h1>{{title}}</h1>
    <div class="content">
      <ul>
        <li *ngFor='let i of pocketPanelBody'> {{i}} </li>
      </ul>
    </div>
  </div>
  <div slot="footer">
    <wdpr-button theme="secondary" (click)="closeRightPanel()">Close</wdpr-button>
    <wdpr-button theme="primary" (click)="saveClick()">Submit</wdpr-button>
  </div>
</wdpr-pocket-panel>
