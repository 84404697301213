<ng-container *ngIf="distributors$ | async as distributors">
  <h4 class="total">Total: {{distributors.length}}</h4>
  <div class="card-grid">
  <span *ngFor="let dist of distributors" class="card-wrapper">
    <wdpr-card routerLink="/web/distributors/{{dist.name.code}}">
      <div slot="card-content">
        <div class="title row">
          {{dist.name.value}}
        </div>
        <div class="row details">
          <span class="item">Active: {{dist.active}}</span>
          <span class="item">Code: {{dist.name.code}}</span>
        </div>
      </div>
    </wdpr-card>
  </span>
  </div>
</ng-container>
