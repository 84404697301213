import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements AfterViewInit {

  apiList = JSON.stringify([{ 'uri': 'assets/i18n/error-translations.json' }]);

  @ViewChild('myDprdTranslate') translateElement: ElementRef;
  @ViewChild('errorElement') errorElement: ElementRef;

  ngAfterViewInit(): void {
    this.translateElement.nativeElement.loadContents().then(content => {
      this.errorElement.nativeElement['content'] = content;
    });
  }

}

