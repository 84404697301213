<div class="environment-wrapper">
  <i class="environment-icon di di-account-settings"></i>
  <wdpr-single-select
    #environmentInput
    class="environment-input"
    [attr.options]="JSON.stringify(environmentOptions)"
    [attr.value]="initialEnvironment"
    (item-selected)="onEnvironmentSelected($event)"
  ></wdpr-single-select>
</div>
