import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-stopsell-pocket-panel',
  templateUrl: './stopsell-pocket-panel.component.html',
  styleUrls: ['./stopsell-pocket-panel.component.scss']
})
export class StopsellPocketPanelComponent {

  @ViewChild('rightPanel') rightPanel: ElementRef;
  @Input() title: string;
  @Input() pocketPanelBody: string[];

  @Output() saveStopSell = new EventEmitter();

  openRightPanel() {
    this.rightPanel.nativeElement.open();
  }

  closeRightPanel() {
    this.rightPanel.nativeElement.close();
  }

  saveClick(){
    this.closeRightPanel();
    this.saveStopSell.emit();
  }

}
