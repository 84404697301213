import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AppPagesModule } from '@app/pages/app.pages.module';
import { CORRELATION_CONFIG_PROVIDER } from '@app/providers/correlation-provider';
import { LOGASARUS_PROVIDER } from '@app/providers/logasarus-provider';
import { OIDC_INTERCEPTOR_PROVIDER } from '@app/providers/oidc-interceptor-provider';
import { ConfigService } from '@services/config/config.service';
import { ErrorHandlerService } from '@services/error-handler/error-handler.service';
import { MyIdService } from '@services/myid/myid.service';
import { RouteListenerService } from '@services/router-changes/route-listener.service';
import { CookieModule } from 'ngx-cookie';

@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => async () => {

        try {
          return await config.load();
        } catch (err) {
          console.log(err);

          return {};
        }

      },
      deps: [ConfigService],
      multi: true
    },
    CORRELATION_CONFIG_PROVIDER,
    OIDC_INTERCEPTOR_PROVIDER,
    LOGASARUS_PROVIDER,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ],
  imports: [
    BrowserModule,
    AppPagesModule,
    AppRoutingModule,
    CookieModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private myIdService: MyIdService,
    private routeChanges: RouteListenerService // Eagerly loading this service so bindings can occur
  ) {
    // When the application shell is loaded, load our pre-authenticated details
    this.myIdService.restoreMyIdSession(true);
  }

}
