import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { StopsellTask } from '@interfaces/stopsell-task';
import { StopsellTaskRequest } from '@interfaces/ttc/stopsell-task-request';
import { StopSellType } from '@services/trade-utility/stop-sell-type';
import { TradeUtilityService } from '@services/trade-utility/trade-utility.service';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { BlockableUI } from 'primeng/api';
import { firstValueFrom, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-stopsell-status',
  templateUrl: './stopsell-status.component.html',
  styleUrls: ['./stopsell-status.component.scss']
})
export class StopsellStatusComponent implements OnInit, OnDestroy {

  @Input() stopsellType: StopSellType;
  @Input() targetElement: BlockableUI;

  protected getStatusSubscription: Subscription;
  protected lastStatusResponse: StopsellTask[] = [];
  protected blockedElementStatus = false;

  constructor(
    private tus: TradeUtilityService,
    private logger: LoggerService
  ) {

  }

  ngOnInit(): void {

    this.prepareStatusSubscriber();

  }

  ngOnDestroy(): void {
    this.getStatusSubscription?.unsubscribe();
  }

  /**
   *
   */
  prepareStatusSubscriber() {

    this.getStatusSubscription = timer(0, 10000).subscribe(x => {

      const request: StopsellTaskRequest = {
        headers: {
          requestorType: 'TTCSpa'
        },
        params: {
          status: 'IN_PROGRESS'
        }
      };

      firstValueFrom(this.tus.getStopsellStatus(request)).then((response) => {
        this.lastStatusResponse = response;
        this.blockedElementStatus = this.lastStatusResponse.length > 0;
      }).catch((reason) => {
        this.logger.log('', { contextMsg: 'Failed to retrieve in-progress stop-sell statuses', reason });
      });

    });

  }

  getLastStatusTaskId() {
    return this.lastStatusResponse[0].taskId.value;
  }

  getLastStatusClientName() {
    if(this.lastStatusResponse[0]?.personSummary?.personName) {
      return `${this.lastStatusResponse[0].personSummary.personName?.given} ${this.lastStatusResponse[0].personSummary.personName?.surname}`;
    } else {
      return `${this.lastStatusResponse[0].personSummary?.email}`;
    }
  }

  getLastStatusStopsellType() {
    return this.lastStatusResponse[0].request.type;
  }

}
