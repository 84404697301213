<div [ngClass]="{'informational-block': showInformationalMessage}">
  <wdpr-informational-message
    #WdprInformationalMessage
    type="page-level"
    (click)="informationalMessageToLogin()"
    [attr.message]="informationalMessage"
    [attr.link-text]="informationalLink"
    [attr.show]="showInformationalMessage">
  </wdpr-informational-message>
</div>

<wdpr-header
  #WdprHeader
  id="hamburger-profile-header"
  title="Travel Trade Connect Admin Console"
  sidebar-title="Navigation"
  [attr.profile-title]="profilePanelTitle"
  hamburger-menu
  class="{{currentProperty}}"
  [attr.user-badge]="userBadge">
  <div class="environment-input-wrapper" slot="custom-badge">
    <app-environment-switcher></app-environment-switcher>
  </div>
  <div class="body" slot="sidebar-panel">
    <app-panel-nav
            #leftPanelNavigation
            (navigated)="closeNavigationMenu()"
    ></app-panel-nav>
  </div>
  <div class="body" slot="profile-panel">
    <div slot="profile-panel-footer">
      <wdpr-button theme="secondary" (click)="profileNavigateToRostr()">View Profile</wdpr-button>
      <wdpr-button theme="primary" (click)="profileNavigateToLogout()">Logout</wdpr-button>
    </div>
  </div>
</wdpr-header>
