import { Component, OnInit } from '@angular/core';
import { CardGridConfig } from '@interfaces/card-grid-config';

@Component({
  selector: 'app-stopsell-landing-page',
  templateUrl: './stopsell-landing-page.component.html',
  styleUrls: ['./stopsell-landing-page.component.scss']
})
export class StopsellLandingPageComponent implements OnInit {

  cards: CardGridConfig[] = [];

  ngOnInit(): void {
    this.cards = [
      {
        title: 'Global Stopsell',
        icon: 'di-global-languages',
        url: '/web/stop-sell/global',
        desc: 'Perform a Global Stopsell across all known distributors & their points of sales.'
      },
      {
        title: 'Channel Manager Stopsell',
        icon: 'di-all-parks-and-resorts',
        url: '/web/stop-sell/channel-manager',
        desc: 'Perform a Channel Manager Stopsell for all given distributors.'
      },
      {
        title: 'Distributor Stopsell',
        icon: 'di-leadership',
        url: '/web/stop-sell/distributor',
        desc: 'Perform a Distributor Stopsell across select point of sales.'
      },
      {
        title: 'Product Type Stopsell',
        icon: 'di-in-park-merchandise',
        url: '/web/stop-sell/product-type',
        desc: 'Perform a product type stopsell for a given channel-manager and property-brand.'
      },
      {
        title: 'Standalone Ticket Stopsell',
        icon: 'di-certificate',
        url: '/web/stop-sell/standalone-ticket',
        desc: 'Perform a standalone ticket stopsell for a given channel-manager and property-brand.'
      },
      {
        title: 'Package Code Stopsell',
        icon: 'di-booking-rooms-packages',
        url: '/web/stop-sell/package-code',
        desc: 'Perform a package code stopsell for a given channel-manager and property-brand.'
      }
    ];
  }



}
