import { Component, OnInit } from '@angular/core';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { Observable, forkJoin, map, mergeMap, finalize } from 'rxjs';
import { TradeUtilityService } from "@services/trade-utility/trade-utility.service";
import { ConfigService } from '@services/config/config.service';
import { DistributorsRequest } from '@interfaces/ttc/distributors-request';

@Component({
  selector: 'app-distributors-page',
  templateUrl: './distributors-page.component.html',
  styleUrls: ['./distributors-page.component.scss']
})
export class DistributorsPageComponent implements OnInit {

  distributors$: Observable<any>;
  JSON: JSON;

  constructor(
    private tus: TradeUtilityService,
    private configs: ConfigService,
    private activityService: ActivityIndicatorService
  ) {
    this.JSON = JSON;
  }

  ngOnInit(): void {
    this.distributors$ = this.fetchDistributors();
  }

  fetchDistributors(): Observable<any> {
    this.activityService.show("Retrieving Distributors...");

    return this.tus.getChannelManagers()
      .pipe(
        mergeMap(data => {

          const distributorReqs = data.map((item) => {
            const request: DistributorsRequest = {
              headers: {
                'X-Fresh-Data': 'false',
                requestorType: 'TTCSpa',
                requestorId: item['name'],
                propertyBrand: 'WDW'
              },
              params: {}
            };

            return this.tus.getDistributors(request);
          });

          return forkJoin(distributorReqs).pipe(map(resp => resp.flat()), finalize(() => { this.activityService.hide();}));
        })
      );

  }

}
