<wdpr-toast #toastError duration="6000" distance="45"></wdpr-toast>
<wdpr-toast #toastSuccess duration="6000" distance="45"></wdpr-toast>

<wdpr-tabs selected-tab-index="0">

  <wdpr-tab id="tab-a0">Send</wdpr-tab>
  <wdpr-tab id="tab-a1">Sent History</wdpr-tab>

  <div slot="body" class="communication-wrapper">

    <!-- Main -->
    <div data-tab-index="0" tabindex="0" labelledby="tab-a0">
      <p-panel #template_config id="template_config" [showHeader]="true" header="Template & Audience">
        <div class="template-config">

          <wdpr-single-select
            (item-selected)="onTemplateSelected($event)"
            [options]="selectTemplatesList"
            id="templateSelect"
            label="Email Template">
          </wdpr-single-select>

          <wdpr-multi-select (selected-changed)="onToSelected($event)" [options]="allAudiencesWithKeyValueList"
                             checkbox="true" id="selectTo"
                             label="Audience TO"
                             placeholder="Select TO"
                             select-all="true">
          </wdpr-multi-select>

          <wdpr-button theme="primary" class="btn-send" id="sendBtn" (click)="sendBtnOnClick()">Send</wdpr-button>
        </div>
      </p-panel>
      <p-panel #template_variables id="template_variables" *ngIf="selectedTemplate" [showHeader]="true" header="Template Variables">
        <div *ngFor="let template of substitutionDataMap" style="padding-top: 15px">

          <p-calendar
            *ngIf="template.key.startsWith('date')"
            [id]="template.key"
            [(ngModel)]="template.value"
            placeholder="{{template.key}}"
            [showTime]="true"
            [showSeconds]="true"
            (focus)="loadPreview()">
          </p-calendar>

          <wdpr-input
            *ngIf="template.key.startsWith('text')"
            show-label-with-placeholder
            [id]="template.key" [(ngModel)]="template.value"
            [ngModelOptions]="{ updateOn: 'blur' }"
            placeholder="{{template.key}}"
            label="{{template.key}}"
            ngDefaultControl
            (ngModelChange)="loadPreview()"
          ></wdpr-input>

        </div>
      </p-panel>

      <p-panel #email_preview id="email_preview" *ngIf="selectedTemplate" [showHeader]="true" header="Email Preview">
        <div class="preview">
          <p-card>
            <p>
              <b>TO: </b>{{audienceTOAddressString}}<br>
            </p>
            <p><br><b>Subject:</b> {{templateSubjectDisplay}}</p>
            <div style="padding-top: 20px" [innerHTML]="templatePreviewDisplay"></div>
          </p-card>
        </div>
      </p-panel>
    </div>

    <!-- History -->
    <div data-tab-index="1" tabindex="-1" labelledby="tab-a1">
      <p-accordion [activeIndex]="0">
        <p-accordionTab header="<p>Foo</p>">
          <p>
          </p>
        </p-accordionTab>
      </p-accordion>
    </div>

  </div>
</wdpr-tabs>
