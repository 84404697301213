import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteListenerService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {

    console.log('Setting up router listener');

    this.currentUrl = this.router.url;

    this.router.events
      .pipe(
          filter((evt: unknown) => evt instanceof RoutesRecognized),
          pairwise()
      )
      .subscribe((events: unknown) => {

        const urlEvents = events as RoutesRecognized[];

        if (urlEvents) {
          this.previousUrl = events[0].urlAfterRedirects;
          this.currentUrl = events[1].urlAfterRedirects;
        }

      });

  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

}
