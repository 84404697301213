import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ErrorCharacterType } from '@core/components/error-character-modal/error-character-type';

@Component({
  selector: 'app-error-character-modal',
  templateUrl: './error-character-modal.component.html',
  styleUrls: ['./error-character-modal.component.scss']
})
export class ErrorCharacterModalComponent implements AfterViewInit {

  hidden = true;

  @ViewChild('myDprdTranslate') translateElement: ElementRef;
  apiList = JSON.stringify([{ 'uri': 'assets/i18n/error-translations.json' }]);

  @ViewChild('errorElement') errorElement: ElementRef;
  status = 500;

  ngAfterViewInit() {
    this.translateElement.nativeElement.loadContents().then(content => {
      this.errorElement.nativeElement['content'] = content;
    });
  }


  show(error: ErrorCharacterType) {

    console.log('Showing modal');
    this.status = error.valueOf();
    this.hidden = false;

  }

  hide() {
    this.hidden = true;
  }



}
