import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@services/config/config.service';
import { Observable } from "rxjs";
import { Product } from '@interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class TradeProductService {

  private configs: object;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
    this.configs = this.configService.get();
  }

  public getProducts(channelManager: string, destination: string, productType: string): Observable<Product[]> {

    const options = {
      headers: {
        echotoken: 'TTC-UI-' + (new Date()).valueOf().toString(),
        requestorid: channelManager,
        propertybrand: destination
      },
      params: {
        productType: productType
      }
    };

    return this.httpClient.get<[]>(
      `${this.configs['WEB_API_BASE_URL']}/products`, options
    );

  }
}
