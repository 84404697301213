/**
 * Provider configuration for the RA Logger.
 */
export const CORRELATION_CONFIG_PROVIDER = {
  provide: 'CORRELATION_CONFIG',
  useValue: {
    wdprConversationCookieName: 'Conversation_UUID',
    wdprConversationHeader: 'X-Conversation-Id',
    wdprCorrelationHeader: 'X-Correlation-Id',
    wdprEndpointFilter: '/\\/log\\/?/'
  }
};
