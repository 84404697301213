<div class="card-grid">

  <span class="card-wrapper" *ngFor="let card of config">
    <wdpr-card [routerLink]="card.url">
      <div class="card-content-wrapper" slot="card-content">
        <i class="icon di {{card.icon}}"></i>
        <div class="card-content">
          <div class="title row">
            {{card.title}}
          </div>
          <div class="body row">
            {{card.desc}}
          </div>
        </div>
      </div>
    </wdpr-card>
  </span>

</div>

