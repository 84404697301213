import { Component, ElementRef, ViewChild } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { SparkpostTemplate } from '@interfaces/sparkpost-template';
import { SparkpostTemplateResult } from '@interfaces/sparkpost-template-result';
import { EmailService } from '@services/email/email.service';
import { SparkpostSubstitutionData } from '@interfaces/sparkpost-substitution-data';
import { SparkpostTransmission } from '@interfaces/sparkpost-transmission';
import { catchError, firstValueFrom } from 'rxjs';
import '@wdpr/toast';
import '@wdpr/button';
import { SparkpostRecipient } from '@interfaces/sparkpost-recipient';
import { DistributionList } from "@interfaces/distribution-list";
import { KeyValueData } from "@interfaces/key-value-data";

@Component({
  selector: 'app-communications-page',
  templateUrl: './communications-page.component.html',
  styleUrls: ['./communications-page.component.scss']
})

export class CommunicationsPageComponent {

  @ViewChild('toastSuccess') toastSuccess: ElementRef;
  @ViewChild('toastError') toastError: ElementRef;

  templatesList: SparkpostTemplateResult[] = [];

  selectedTemplateId: string;
  selectedTemplate: SparkpostTemplate;
  selectTemplatesList: KeyValueData[] = [];

  templatePreviewDisplay: string;
  templateSubjectDisplay: string;

  substitutionDataMap: KeyValueData[] = [];

  allAudiencesWithKeyNameEmailsList: DistributionList[] = [];
  allAudiencesWithKeyValueList: { key: string, value: string }[] = [];
  audienceTOAddressSet: Set<string> = new Set();
  audienceTOAddressString: string;

  sparkpostTransmission: SparkpostTransmission = {} as SparkpostTransmission;

  audiencePlaceHolder = (selected) => {
    const options = {
      '0': 'Select Item(s)',
      '1': selected[0] ? selected[0].text : '',
      'default': `${selected.length} Items`
    };

    return (options[selected.length] || options['default']);
  };

  constructor(
    private emailService: EmailService,
    private logger: LoggerService,
    private activityService: ActivityIndicatorService
  ) {
  }

  ngOnInit(): void {
    this.loadTemplateList();
    this.loadAudience();
  }

  loadTemplateList() {
    this.activityService.show('Retrieving Template List...');
    firstValueFrom(this.emailService.getTemplatesList()).then((templateListResponse) => {

      templateListResponse.map(item => {
        this.templatesList.push(item);
        this.selectTemplatesList.push({ key: item.id, value: item.name });
      });

    }).finally(() => {
      this.activityService.hide();
    });
  }

  loadAudience() {

    // Load the distributions lists from EC2
    this.allAudiencesWithKeyNameEmailsList.push({ key:'Studio Groot', emails:['daniel.barreto@disney.com','andrew.w.polaskey@disney.com','raja.shiva.prasad.x.deeti.-nd@disney.com','mohammed.z.qureshi.-nd@disney.com'] });
    this.allAudiencesWithKeyNameEmailsList.push({ key:'Redeam', emails:['daniel.barreto@disney.com','andrew.w.polaskey@disney.com', 'email3@disney.com'] });
    this.allAudiencesWithKeyNameEmailsList.push({ key:'Derbysoft', emails:['daniel.barreto@disney.com','andrew.w.polaskey@disney.com', 'email3@disney.com'] });

    // Map select box
    this.allAudiencesWithKeyNameEmailsList.forEach(distributionList => {
        this.allAudiencesWithKeyValueList.push({ key: distributionList.key, value: distributionList.key });
    });

  }

  onTemplateSelected(event) {

    this.selectedTemplateId = event.detail.key;
     this.loadTemplate();
  }

  extractFieldsListFromTemplate() {
    const body: string = this.selectedTemplate.content.html;
    this.substitutionDataMap = [];
    if (body && body !== "") {
      let startPointer = 0;
      let endPointer = 0;

      let strIndexStart = 0;
      let strIndexEnd = 0;

      while (strIndexStart != -1 || strIndexEnd != -1) {
        strIndexStart = body.indexOf('{{', startPointer);
        if (strIndexStart == -1) break;

        strIndexEnd = body.indexOf('}}', strIndexStart);
        if (strIndexEnd == -1) break;

        if (strIndexStart != strIndexEnd) {
          this.substitutionDataMap.push({ key: body.substring(strIndexStart + 2, strIndexEnd), value: '' });
          startPointer = strIndexEnd;
          endPointer = strIndexEnd;
        }
      }
    }
  }

  private loadTemplate() {

    this.activityService.show('Retrieving Template...');

    firstValueFrom(this.emailService.getTemplate(this.selectedTemplateId))
      .then((template) => {
        this.selectedTemplate = template;
        this.extractFieldsListFromTemplate();
        this.activityService.hide();
        this.templateSubjectDisplay = template.content.subject;
        this.templatePreviewDisplay = template.content.html;
      }).catch( (error) => {
        this.logger.error('', { contextMsg: 'Failed to retrieve Template', error });
    }).finally(() => {
      this.activityService.hide();
    });

  }

   mapSubstitutionData() {

     const looseObject: { [key: string]: string } = {};

     this.substitutionDataMap.forEach( item => {
       looseObject[item.key] = item.value;
     });

     return looseObject;
  }

  sendBtnOnClick() {
    this.activityService.show('Sending message...');

    this.sparkpostTransmission = {} as SparkpostTransmission;

    const recipients: SparkpostRecipient[] = [];

    for(const toEmail of this.audienceTOAddressSet ){
      const recipientTo: SparkpostRecipient = {} as SparkpostRecipient;
      recipientTo.address = { email: toEmail };
      recipientTo.substitution_data = this.mapSubstitutionData();
      recipients.push(recipientTo);
    }

    this.sparkpostTransmission.recipients = recipients;

    this.sparkpostTransmission.content = { template_id: this.selectedTemplateId };

    firstValueFrom(this.emailService.emailTransmission(this.sparkpostTransmission))
      .then((transmissionResponse) => {
        this.activityService.hide();

        const msg = `Transmission: ${transmissionResponse.results.id}
          Rejected: ${transmissionResponse.results.total_rejected_recipients}
          Accepted:  ${transmissionResponse.results.total_accepted_recipients}`;

        this.openToast(msg, true);
      }).catch((error) => {
      this.logger.error('', { contextMsg: 'Failed on messages transmission', error });
      this.openToast(error.message, false);
    }).finally(() => {
      this.activityService.hide();
    });

  }

  loadPreview() {
    this.activityService.show('Retrieving Template Preview...');

    const substData: SparkpostSubstitutionData = {} as SparkpostSubstitutionData;
    substData.substitution_data = this.mapSubstitutionData();

    firstValueFrom(this.emailService.emailPreview(this.selectedTemplateId, substData)).then((preview) => {
      this.templateSubjectDisplay = preview.subject;
      this.templatePreviewDisplay = preview.html;
    }).finally(() => {
      this.activityService.hide();
    });

  }

  onToSelected(event) {
    this.audienceTOAddressSet = new Set();

    if (event && event.detail && event.detail.value.length > 0) {
      event.detail.value.forEach(list => {
        this.addEmails(list.value);
      });
    }

    this.printSet();
  }

  openToast(message: string, isSuccess: boolean) {
    if (isSuccess) {
      this.toastSuccess.nativeElement.toastSuccess = isSuccess;
      this.toastSuccess.nativeElement.message = message;
      this.toastSuccess.nativeElement.open();
    } else {
      this.toastError.nativeElement.toastSuccess = isSuccess;
      this.toastError.nativeElement.message = message;
      this.toastError.nativeElement.open();
    }
  }

  private addEmails(key: string) {
    const temp = this.allAudiencesWithKeyNameEmailsList.find(audienceList => audienceList.key == key);
      temp.emails.forEach( email => {
        this.audienceTOAddressSet.add(email);
      });
  }

   printSet() {
     this.audienceTOAddressString= '';
     let separator = '';
    this.audienceTOAddressSet.forEach( (value) => {
      this.audienceTOAddressString = this.audienceTOAddressString + separator + value;
      if(separator == ''){
        separator= ', ';
      }
    });
  }

}
