import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '@services/config/config.service';
import { ConfigEnvironment } from '@interfaces/config-environment';
import { Router } from '@angular/router';
import { WindowRefService } from '@services/window-ref/window-ref.service';

@Component({
  selector: 'app-environment-switcher',
  templateUrl: './environment-switcher.component.html',
  styleUrls: ['./environment-switcher.component.scss']
})
export class EnvironmentSwitcherComponent implements OnInit {

  @ViewChild('environmentInput') envInputElement: ElementRef;

  initialEnvironment: string;
  environmentOptions: any[];
  availableEnvironments: ConfigEnvironment[];

  JSON = JSON;

  constructor(
    private config: ConfigService,
    private router: Router,
    private window: WindowRefService
  ) {

  }

  ngOnInit() {

    const envOptions = this.config.getEnvironmentOptions();

    this.availableEnvironments = envOptions;

    this.environmentOptions = envOptions.map(item => {
      return { key: this.generateOptionsKey(item), value: this.generateOptionsValue(item) };
    });

    const currentEnvironment = this.config.getCurrentEnvironment();
    this.initialEnvironment = this.generateOptionsKey(currentEnvironment);

  }

  private generateOptionsKey(configEnv: ConfigEnvironment) {
    return configEnv.propertyBrand + "::" + configEnv.environment;
  }

  private generateOptionsValue(configEnv: ConfigEnvironment) {
    return configEnv.propertyBrand + ' (' + configEnv.environment + ')';
  }

  onEnvironmentSelected($event) {
    const selectedEnv = this.availableEnvironments[$event.detail.index];

    return this.config.switchCurrentEnvironment(selectedEnv).then(() => {

      this.router.navigate(['/web/home']).then(() => {
        this.window.nativeWindow.location.reload();
      });

    });

  }

}
