import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';
import { getValueOrDefault } from '@app/utilities/get-value-or-default';
import { DataTableRow } from '@interfaces/data-table-row';
import { Distributor } from '@interfaces/distributor';
import { FlatDataTable } from '@interfaces/flat-data-table';
import { PointOfSale } from '@interfaces/point-of-sale';
import { StopSellDistributor } from '@interfaces/stopsell-distributor';
import { ChannelManager } from '@interfaces/ttc/channel-managers';
import { ProductType } from '@services/trade-product/product-type';
import { StopSellType } from "@services/trade-utility/stop-sell-type";
import { TradeProductService } from '@services/trade-product/trade-product.service';
import { TradeUtilityService } from "@services/trade-utility/trade-utility.service";
import { LoggerService } from '@wdpr/ra-angular-logger';
import { firstValueFrom } from 'rxjs';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { StopsellPocketPanelComponent } from '@core/components/stopsell-pocket-panel/stopsell-pocket-panel.component';

type DistributorPackage = { distributor: Distributor, packageCodes: Set<string> };

@Component({
  selector: 'app-stopsell-room-package-page',
  templateUrl: './stopsell-room-package-page.component.html',
  styleUrls: ['./stopsell-room-package-page.component.scss']
})
export class StopsellRoomPackagePageComponent implements OnInit {

  readonly JSON = JSON;
  readonly DistributorPackage: { distributor: Distributor, packageCodes: Set<string> };

  title = "Stopsell - Room Package";
  protected readonly stopsellType = StopSellType.PACKAGE_CODE;

  stopsellResponseLastOnData: any;
  stopsellResponseLastOffData: any;

  distributorList: Distributor[] = [];
  distributorPackages: Map<string, DistributorPackage> = new Map();

  @ViewChild('stopSellPocketPanel') stopsellPocketPanelComponent: StopsellPocketPanelComponent;
  pocketPanelBodyStopsell: string[] = [];
  pocketPanelTitle = 'Room Package Stopsell';

  @ViewChild('dataTable') dataTable: FlatDataTableComponent;
  dataTableConfig: FlatDataTable;
  datum: Promise<DataTableRow[]>;
  rawTableDatum: any[] = [];

  @ViewChild('dynamicTabs') dynamicTabs: ElementRef;
  selectedTabIndex = 0;

  @ViewChild('cmSelect') cmSelect: ElementRef;
  channelManagerSelected: string;
  channelManagerSelectOptions: string[];
  channelManagers: ChannelManager[] = [];

  @ViewChild('destSelect') destinationSelectInput: ElementRef;
  destinationSelectOptions: string[] = [];
  destData: string[];
  destSelected = "";

  @ViewChild('packageCodeSelect') packageCodeSelectInput: ElementRef;
  packageCodesSelected: string[] = [];
  packageCodeSelectOptions: string[] = [];
  packageCodePlaceholder = (selected) => {
    const options = {
      '0': 'Select Item(s)',
      '1': selected[0] ? selected[0].text : '',
      'default': `${selected.length} Items`
    };

    return (options[selected.length] || options['default']);
  };

  @ViewChild('stopSellToggleBtn') stopSellToggleBtn: ElementRef;

  @ViewChild('remarksInput') remarksInput: ElementRef;
  remarksEntered = "";

  constructor(
    private tus: TradeUtilityService,
    private tps: TradeProductService,
    private activityService: ActivityIndicatorService,
    private logger: LoggerService
  ) {
  }

  ngOnInit(): void {

    this.populateChannelManagers();
    this.initializeDataTable();

  }

  private initializeDataTable() {
    this.dataTableConfig = {
      columns: [
        {
          label: 'On StopSell',
          field: 'stopsellStatus',
          type: 'status'
        },
        {
          label: 'Distributor Code',
          field: 'code',
          sortable: true,
        },
        {
          label: 'Applicable Packages',
          field: 'packages',
          sortable: true
        },
        {
          label: 'POS Count',
          field: 'pos',
          sortable: true
        },
        {
          label: 'Name',
          field: 'name',
          sortable: true
        },
        {
          label: 'Contract Active',
          field: 'active',
          type: 'boolean'
        }
      ],
      displayGrid: false,
      enableEditableRows: false,
      enableLazyScroll: true,
      enableQuickFilter: true,
      enableSelection: true,
      lockManualSelection: false,
      primaryKey: 'code',
      size: 'sm'
    };
  }

  /**
   * Populates the channel manager dropdown with options from TUS.
   * @private
   */
  private populateChannelManagers() {

    this.activityService.show("Loading Channel Managers...");

    firstValueFrom(this.tus.getChannelManagers()).then((cmResponse) => {

      this.channelManagers = cmResponse;
      this.channelManagerSelectOptions = this.channelManagers.filter(
        item => item.productTypes.find(type => type.name.code === 'Room_Package')
      ).map(
        item => item.name
      );

    }).catch(reason => {
      this.logger.error('', { contextMsg: 'Failed to retrieve channel managers', reason });
    }).finally(() => {
      this.activityService.hide();
    });

  }

  /**
   * Populates destination dropdown with the TUS CM response.
   * @private
   */
  private populateDestinations() {

    const cm = this.channelManagers.find((cm) => {
      return cm.name === this.channelManagerSelected;
    });

    if(cm) {
      this.destinationSelectOptions = cm.propertyBrands.map((propertyBrand) => {
        return propertyBrand.code;
      });
    }

  }

  /**
   * Populates the product types dropdown with information from TUS.
   * @private
   */
  private populatePackageCodes() {

    if(this.channelManagerSelected) {

      this.activityService.show("Retrieving Product List...");
      (async () => {

        const productPackageList = firstValueFrom(
          this.tps.getProducts(this.channelManagerSelected, this.destSelected, ProductType.ROOM_PACKAGE)
        );

        const distributors = firstValueFrom( this.tus.getDistributors({
          headers: {
            'X-Fresh-Data': 'false',
            requestorType: 'TTCSpa',
            requestorId: this.channelManagerSelected,
            propertyBrand: this.destSelected
          },
          params: {
            field: 'pos'
          }
        }));

        try {

          const rspPackageList = await productPackageList;
          const rspDistributors = await distributors;

          this.distributorPackages.clear();
          rspDistributors.forEach((distributor) => {
            const distPackages = {
              distributor,
              packageCodes: new Set<string>
            };
            this.distributorPackages.set(distributor.name.code, distPackages);
          });

          // Map Distributors to Package Codes
          this.packageCodeSelectOptions = rspPackageList.map((product) => {
            product.distributors.forEach((distributor) => {
              const distWithPackages = this.distributorPackages.get(distributor.name.code);
              distWithPackages.packageCodes.add(product.ratePlan.ratePlanCode.code);
            });

            return product.ratePlan.ratePlanCode.code;
          });

          this.packageCodeSelectInput.nativeElement.selected = this.packageCodeSelectOptions;

        } catch (error) {
          this.logger.error('', { contextMsg: 'Failed to retrieve filtered distributor list by products', error });
        }

      })().finally(() => {
        this.activityService.hide();
      });

    }

  }

  /**
   * Populates the data table with distributors.
   * @private
   */

  private populateDataTable() {

    if(this.packageCodesSelected?.length === 0 && this.packageCodesSelected[0]?.length === 0) {
      this.datum = Promise.resolve([]);
    }

    this.rawTableDatum = Array.from(this.distributorPackages.values()).filter(distPackage => {
      // Filter our distributors for the table based on which ones currently have one of the selected packages
      return this.packageCodesSelected.find((pkgCode) => {
        return distPackage.packageCodes.has(pkgCode);
      });
    }).map((distPackage) => {
      return {
        stopsellStatus: this.isDistributorOnStopSell(this.destSelected, distPackage.distributor),
        code: distPackage.distributor.name.code,
        packages: distPackage.packageCodes.size,
        pos: distPackage.distributor.propertyBrands[this.destSelected]?.pos.length,
        name: distPackage.distributor.name.value,
        active: distPackage.distributor.active
      };
    });

    this.datum = Promise.resolve(this.rawTableDatum);

  }

  /**
   * Determines if POS is on Stop-sell with matching criteria.
   * @param pos
   * @private
   */
  private isPOSOnStopSell(pos: PointOfSale[]): boolean {

    if(pos) {

      const foundPos = pos.find((posItem) => {
        return posItem.stopSells?.find((stopsellItem) => {

          const isPackageCode = stopsellItem.type === StopSellType.PACKAGE_CODE;

          const isSelectedType = stopsellItem.values?.find((typeItem) => this.packageCodesSelected.includes(typeItem));

          return isPackageCode && isSelectedType;

        });
      });

      return !!foundPos;

    } else {
      return false;
    }

  }

  /**
   * Determines whether a distirbutor is currently on stop-sell
   * @param destination
   * @param distributor
   * @private
   */
  private isDistributorOnStopSell(destination: string, distributor: Distributor) {

    const pos = getValueOrDefault(destination, {}, distributor?.propertyBrands)?.pos;

    if(pos) {
      return this.isPOSOnStopSell(pos);
    } else {
      return false;
    }

  }

  private mapToStopSell(selectedDataTableRows: DistributorPackage[], filterToStopsellOn: boolean): StopSellDistributor[] {

    return selectedDataTableRows.map((row) => {

      // Get the distributor information by the index in our data-table
      const distributor = row.distributor;

      const isDistributorOnStopsell = this.isDistributorOnStopSell(this.destSelected, distributor);

      if(filterToStopsellOn === isDistributorOnStopsell) {
        const pos = distributor.propertyBrands ? distributor.propertyBrands[this.destSelected].pos : [];

        return {
          name: { value: distributor.name.code },
          pos: pos.map((item) => {
            return {
              id: item.id
            };
          })
        };

      } else {

        return undefined;

      }
    }).filter((distributor) => { return distributor; });

  }


  onStopsellToggleClicked() {

    this.pocketPanelTitle = `Modifying ${this.dataTable.getSelectedData().length} distributors for Package Stopsell`;
    this.pocketPanelBodyStopsell = [
      "Channel Manager : " + this.channelManagerSelected,
      "Destination : " + this.destSelected,
      "Package Codes : " + this.packageCodesSelected
    ];

    this.stopsellPocketPanelComponent.openRightPanel();
  }

  onSelectChannelManager($event: any) {
    this.channelManagerSelected = $event.detail.value;
    this.populateDestinations();

    // Reset everything when we change channel managers
    if(this.destSelected.length > 0) {
      this.destSelected = "";
      this.destinationSelectInput.nativeElement.value = "";
      this.packageCodesSelected = [""];
      this.packageCodeSelectInput.nativeElement.value = "";
      this.remarksEntered = "";
    }
  }


  onSelectDestination($event: any) {

    this.destSelected = $event.detail.value;

    if(this.destSelected.length > 0) {
      this.populatePackageCodes();
    }

  }

  onSelectPackageCode($event: any) {
    this.packageCodesSelected = $event.detail.value.map(item => { return item.value; });
    this.populateDataTable();
  }

  onToggleStopsell() {

    const selectedData = this.dataTable.getSelectedData().map((selectedRow) => {
      return this.distributorPackages.get(selectedRow['code']);
    });

    this.activityService.show('Performing Product Type Stopsell...');

    // Map Distributor Stop Sell Request
    const selectedBranchDistributorsOnStopsell: StopSellDistributor[] = this.mapToStopSell(selectedData, false);
    const individualDistributorsOnStopsell = selectedBranchDistributorsOnStopsell.length > 0 ? ([{ distributors: selectedBranchDistributorsOnStopsell }]) : ([]);

    const selectedBranchDistributorsOffStopsell: StopSellDistributor[] = this.mapToStopSell(selectedData, true);
    const individualDistributorsOffStopsell = selectedBranchDistributorsOffStopsell.length > 0 ? ([{ distributors: selectedBranchDistributorsOffStopsell }]) : ([]);

    return (async () => {

      try {
        this.activityService.show(`Putting ${individualDistributorsOnStopsell.length} distributors on stop-sell...`);

        if(individualDistributorsOnStopsell.length > 0) {
          this.stopsellResponseLastOnData = await firstValueFrom(
            this.tus.updateStopSell(
              individualDistributorsOnStopsell,
              this.channelManagerSelected,
              this.destSelected,
              this.stopsellType,
              true,
              this.remarksEntered,
              this.packageCodesSelected
            )
          );
        } else {
          console.log('Skipping on stop-sell request, no valid entities found.');
        }

        this.activityService.show(`Taking ${individualDistributorsOffStopsell.length} distributors off stop-sell...`);

        if(individualDistributorsOffStopsell.length > 0) {
          this.stopsellResponseLastOffData = await firstValueFrom(
            this.tus.updateStopSell(
              individualDistributorsOffStopsell,
              this.channelManagerSelected,
              this.destSelected,
              this.stopsellType,
              false,
              this.remarksEntered,
              this.packageCodesSelected
            )
          );
        } else {
          console.log('Skipping off stop-sell request, no valid entities found.');
        }

      } catch (reason) {
        console.log(reason);
        this.logger.error('', { contextMsg: 'Failed to perform stop-sell request chain, impartial results possible', reason });
      } finally {
        this.activityService.hide();
      }

    })();

  }

}
