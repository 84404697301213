import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private zone: NgZone,
    private logger: LoggerService
  ) {
  }

  handleError(error: object): void {

    this.zone.run(() => {
      console.log(error);
      this.logger.error('', { error });
    });

  }
}
