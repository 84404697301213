<wdpr-modal
        class="error-modal"
        [attr.open]="hidden ? (undefined) : true"
        disable-close-on-escape
        disable-close-on-background
        no-padding
        hide-close-icon
        size="x-large"
>

    <div slot="body" style="margin-top: -40px;">
        <div>
            <dprd-translate [attr.api-list]="apiList" #myDprdTranslate></dprd-translate>
            <wdpr-error-panel #errorElement class="error-{{status}} error-panel" [attr.status-code]="status"></wdpr-error-panel>
            <div class="projected-wrapper">
                <ng-content></ng-content>
            </div>
        </div>
    </div>

</wdpr-modal>
