<wdpr-tabs>
  <wdpr-tab id="tab-b0" role="tab" aria-controls="tabpanel-b0">Stopsell</wdpr-tab>
  <wdpr-tab id="tab-b1" role="tab" aria-controls="tabpanel-b1">History</wdpr-tab>
  <div slot="body">
    <div class="tab-content" data-tab-index="0" tabindex="0" id="tabpanel-b0" role="tabpanel" aria-labelledby="tab-b0"
      class="stopsell-main">
      <div>
        <div class="title">
          <h2>Package Component Stopsell </h2>
        </div>
        <wdpr-global-stopsell-form class="header-wrapper">
          <div class="cm-header-wrapper">
            <div class="div-header-label"> Channel Manager *</div>
            <div class="select-wrapper">
              <wdpr-single-select id="cmSelect" [options]="cmData" (item-selected)="onSelectCM($event)"
                placeholder="Select Channel Manager"></wdpr-single-select>
            </div>
          </div>
          <div class="dest-header-wrapper">
            <div class="div-header-label"> Destination *</div>
            <div class="select-wrapper">
              <wdpr-single-select id="destSelect" [disabled]="disableDestDropdown" [options]="destData"
                (item-selected)="onSelectDest($event)" placeholder="Select Destination"></wdpr-single-select>
            </div>
          </div>
          <div class="dest-header-wrapper">
            <div class="div-header-label">Package Component *</div>
            <div class="select-wrapper">
              <wdpr-multi-select id="packageComponentSelected" checkbox="true" [disabled]="disablePackageCompDropdown"
                                 select-all="true" [options]="packageCompData"
                                 (selected-changed)="onSelectPackageComp($event)"
                                 placeholder="Select package component(s)"></wdpr-multi-select>
            </div>
          </div>

          <div class="submit-header-wrapper">
            <wdpr-button [ngClass]="{'changeColor': !stopsellFlag && !disableBtn }" [disabled]="disableBtn" id="submitBtn" (click)="stopsellSubmit()">Toggle
              Stopsell</wdpr-button>
          </div>
        </wdpr-global-stopsell-form>
        <div class="headerPadding">
          <label for="stopsellOnRemark" class="stopsell-remark-label">Remarks *</label>
          <textarea rows="4" cols="80" id="stopsellOnRemark" class="stopsell-remark-textarea"
            (change)="onRemarksInput($event)"
            placeholder="Required remarks about why the stopsell is being used."></textarea>
        </div>
      </div>
      <div class="grid-wrapper">
        <app-filter-data-table [columns]="columns" [rowData]="packageComponentStopSellGridRows" [paginator]="true"
                                   [rows]="10" [showCurrentPageReport]="true" [globalFilterFields]="['name', 'code']"
                                   [totalRecords]="packageComponentStopSellGridRows===undefined ? 0 : packageComponentStopSellGridRows.length"
                                   [tableStyle]="{ 'min-width': '50rem' }" [rowsPerPageOptions]="[10, 25, 50]"></app-filter-data-table>
      </div>

      <wdpr-modal #stopSellOnConfirm show-header-separator="true" hide-close-icon="true" size="small">
        <h4 slot="title" class="heavy">Product Type Stopsell Confirmation</h4>
        <div slot="body">
          <table class="confirm-table">
            <tr>
              <td>{{popupText}}</td>
              <td> : {{distributorStopsellCount}}</td>
            </tr>
            <tr>
              <td>Channel Manager </td>
              <td> : {{channelManager}}</td>
            </tr>
            <tr>
              <td>Destination </td>
              <td> : {{destination}}</td>
            </tr>
            <tr>
              <td>Package Component </td>
              <td> : {{packageComponent}}</td>
            </tr>
          </table>
          <div class="alertButtons">
            <wdpr-button slot="buttons" class="alertButton" id="cancelBtn"
              (click)="cancelBtnOnClick()">Close</wdpr-button>
          </div>
        </div>
      </wdpr-modal>
      <app-stopsell-pocket-panel #PocketPanelComponent [title]="title" (saveStopSell)="showConfirmationModalforOn()"
        [pocketPanelBody]="pocketPanelBodyStopsellON"></app-stopsell-pocket-panel>
    </div>
    <div class="tab-content" data-tab-index="1" tabindex="-1" id="tabpanel-b1" role="tabpanel" aria-labelledby="tab-b1"
      class="stopsell-main">
      <div>
        <div class="title">
          <h2>History </h2>
        </div>
        <div slot="body" class="history-wrapper">
          <app-stopsell-history #stopsellHistory [type]="type"/>
        </div>
      </div>
    </div>
  </div>
</wdpr-tabs>
