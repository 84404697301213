import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableColumn } from '@interfaces/data-table-column';
import { DataTableRow } from '@interfaces/data-table-row';
import { FlatDataTable } from '@interfaces/flat-data-table';
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';

@Component({
  selector: 'app-upload-distributor-page',
  templateUrl: './upload-distributor-page.component.html',
  styleUrls: ['./upload-distributor-page.component.scss']
})
export class UploadDistributorPageComponent {

  file: File = null;
  data: object[];
  headers: DataTableColumn[];
  uploadDistributorGridRows: object[];

  fileExtentions = ".xls, .csv, .xlsx";
  @ViewChild('dataTable') _dataTable: FlatDataTableComponent;
  dataTableConfig: FlatDataTable;

  constructor(
    private activityIndicatorService: ActivityIndicatorService
  ) {

  }

  uploadFiles($event) {
    const file: File = $event;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: WorkBook = read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = utils.sheet_to_json(ws);
      // populates data needed for data table.
      this.headers = [];
      this.uploadDistributorGridRows = [];

      Object.keys(this.data[0] || {}).forEach(key => {
        this.headers.push({
          field: key, label: key
        });
      });

      Object.values(this.data || {}).forEach((value) => {
        this.uploadDistributorGridRows.push(value);
      });

      this.loadDataTable(this.headers, []);
    };
  }

  loadDataTable(columns: DataTableColumn[], datum: DataTableRow[]) {
    this.dataTableConfig = {
      columns: columns,
      displayGrid: false,
      enableEditableRows: false,
      enableLazyScroll: true,
      enableQuickFilter: true,
      enableSelection: true,
      lockManualSelection: true,
      primaryKey: 'rowId',
      size: 'sm'
    };
  }

  runFiles() {
    this.activityIndicatorService.show();
    setTimeout(() => {
      this.activityIndicatorService.hide();
    }, 4000);
  }

}




