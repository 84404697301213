import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { withCache } from '@ngneat/cashew';
import { WindowRefService } from '@services/window-ref/window-ref.service';
import { firstValueFrom } from 'rxjs';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { ConfigEnvironment } from '@interfaces/config-environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _config: object;
  private _envOverride: ConfigEnvironment;

  constructor(
    private http: HttpClient,
    private windowRef: WindowRefService,
    private logger: LoggerService
  ) {
  }

  /**
   * Loads the expanded configuration for the UI post authenticated user.
   */
  async load(): Promise<object> {

    const env = this.getCurrentEnvironment();
    console.log('Loading application configuration for ', env);

    let serviceConfig = {};
    try {
      serviceConfig = await firstValueFrom(this.http.get('/api/config', {
        headers: {
          'x-property-brand': env.propertyBrand
        },
        context: withCache({
          version: 'v1',
          ttl: 1000 * 60 * 30,
          key: 'config'
        })
      }));
    } catch (error) {
      this.logger.error(error);
    }

    // Special case for dev environment, use local static-service
    if(env.environment === 'dev') {
      serviceConfig['WEB_API_BASE_URL'] = 'http://localhost:8626/api';
    }

    console.log('Configuration loaded:', serviceConfig);

    this._config = serviceConfig;

    return this._config;

  }

  get(): object {
    return structuredClone(this._config);
  }

  /**
   * Retrieve configuration from property.
   * @param key
   */
  getValue(key: string): string {
    // Get value of config key:
    return this._config[key];
  }

  async switchCurrentEnvironment(environment: ConfigEnvironment) {
    const storage = this.windowRef.nativeWindow.localStorage;
    storage.setItem('env-application-override', JSON.stringify(environment));

    return environment;
  }

  /**
   * Retrieve the current environment the application thinks it is in.
   */
  getCurrentEnvironment(): ConfigEnvironment {

    if(!this._envOverride) {

      const storage = this.windowRef.nativeWindow.localStorage;
      const storedOverride = storage.getItem('env-application-override');

      if(storedOverride) {
        this._envOverride = JSON.parse(storedOverride);
      } else {
        this._envOverride = {
          propertyBrand: 'WDW',
          environment: this.determineLocalEnvironment()
        };
      }

    }

    return this._envOverride;

  }

  determineLocalEnvironment() {
    // Defines application environment
    let localEnvironment;
    if (window.location.origin.includes('://localhost') || window.location.origin.includes('://127.0.0.1')) {
      localEnvironment = 'dev';
    } else if (window.location.origin.includes('://latest.')) {
      localEnvironment = 'latest';
    } else if (window.location.origin.includes('://stage.')) {
      localEnvironment = 'stage';
    } else {
      localEnvironment = 'prod';
    }

    return localEnvironment;
  }

  getEnvironmentOptions(): ConfigEnvironment[] {

    const currentEnv = this.getCurrentEnvironment().environment;

    if (this.determineLocalEnvironment() === 'dev') {

      return [
        {
          environment: 'dev',
          propertyBrand: 'WDW'
        },
        {
          environment: 'dev',
          propertyBrand: 'DLR'
        },
        {
          environment: 'latest',
          propertyBrand: 'WDW'
        },
        {
          environment: 'latest',
          propertyBrand: 'DLR'
        },
        {
          environment: 'stage',
          propertyBrand: 'WDW'
        },
        {
          environment: 'stage',
          propertyBrand: 'DLR'
        },
        {
          environment: 'prod',
          propertyBrand: 'WDW'
        },
        {
          environment: 'prod',
          propertyBrand: 'DLR'
        }
      ];

    } else {

      return [
        {
          environment: currentEnv,
          propertyBrand: 'WDW'
        },
        {
          environment: currentEnv,
          propertyBrand: 'DLR'
        }
      ];

    }

  }



}
