import { OIDCToken } from '@interfaces/oidc-token';

export interface OIDCSession {
  oidcToken: OIDCToken;
  valid: boolean
}

export const OIDC_EMPTY_SESSION = {
  oidcToken: {
    accessToken: '',
    idToken: '',
    issuedAt: 0,
    expiresAt: 0,
    nonce: '',
    userDetails: {
      identity: '',
      email: '',
      givenName: '',
      familyName: '',
      fullName: '',
      nickname: '',
      profile: ''
    },
    roles: []
  },
  valid: false
};
