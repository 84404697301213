<div class="progress-bar-wrapper" *ngIf="lastStatusResponse.length > 0">
  <span class="status-details">
    <span>
      <i class="di di-alert" style="font-size: 35px; position: absolute;"></i>
    </span>
    <span>
      <div style="padding-left: 60px;">
        <div style="margin-bottom: 10px;">A {{getLastStatusStopsellType()}} stopsell is currently being performed by {{getLastStatusClientName()}}; please wait while task {{getLastStatusTaskId()}} completes.</div>
        <div style="line-height: 0;"><p-progressBar mode="indeterminate" [style]="{ height: '8px', 'padding-left': '35px'}"></p-progressBar></div>
      </div>
    </span>
  </span>
</div>
<p-blockUI [target]="targetElement" [blocked]="blockedElementStatus">
  <i class="pi pi-lock" style="font-size: 3rem"></i>
</p-blockUI>
