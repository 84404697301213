import { ConfigService } from '@services/config/config.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PresignAction } from '@services/storage/presign-action';
import { StoragePresignResponse } from '@services/storage/storage-presign-response';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private configs: object;

  // Bypass interceptors
  private bypassedClient: HttpClient;

  constructor(
    private httpClient: HttpClient,
    private httpBackend: HttpBackend,
    private configService: ConfigService,
    private logger: LoggerService
  ) {
    this.configs = this.configService.get();
    this.bypassedClient = new HttpClient(httpBackend);
  }

  /**
   * Retrieve an S3 signed presign URL from the middleware.
   * @param path : string object key to retrieve or update or create
   * @param action : PresignAction get to retrieve an object, put to store or create an object
   */
  public getPresignUrl(path: string, action: PresignAction): Observable<StoragePresignResponse> {

    return this.httpClient.get<StoragePresignResponse>(
      `${this.configs['WEB_API_BASE_URL']}/files/presigner`,
      {
        params: {
          action,
          path
        }
      }
    );

  }

  /**
   * Uploads to target pre-signed destination.
   * @param presigner
   * @param object
   */
  public uploadWithPresignUrl(presigner: StoragePresignResponse, object: Blob | ArrayBuffer | FormData): Observable<any> {
    return this.bypassedClient.put(presigner.url, object);
  }

}
