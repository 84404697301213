<div class="navigation">
  <div class="nav-group" *ngFor="let navGroup of navigation">
    <h4 *ngIf="navGroup.url"><a (click)="onLinkClick()" routerLink="{{navGroup.url}}">{{navGroup.title}}</a></h4>
    <h4 *ngIf="navGroup.url === undefined && navGroup.title !== undefined">{{navGroup.title}}</h4>
    <ul class="link-list">
      <li class="link-wrapper" *ngFor="let navLink of navGroup.children">
        <a class="link" (click)="onLinkClick()" routerLink="{{navLink.url}}" routerLinkActive="active">{{navLink.label}}</a>
      </li>
    </ul>
  </div>
</div>
