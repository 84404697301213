import { Component, ElementRef, ViewChild } from '@angular/core';
import { PresignAction } from '@services/storage/presign-action';
import { StorageService } from '@services/storage/storage.service';
import { ValidationsService } from '@services/validations/validations.service';
import { concatMap } from 'rxjs';
import { read, WorkBook } from 'xlsx';

@Component({
  selector: 'app-validation-products-page',
  templateUrl: './validation-products-page.component.html',
  styleUrls: ['./validation-products-page.component.scss']
})
export class ValidationProductsPageComponent {

  @ViewChild('validationTabs') _tabs: ElementRef;

  productData: any;
  fileExtentions = ".xls, .csv, .xlsx";

  constructor(
    private validationSvc: ValidationsService,
    private storageSvc: StorageService
  ) {
  }

  onClear($event) {
    this.productData = undefined;
  }

  onLoadValidationFile($event) {

    const file: File = $event as File;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);

    return new Promise<boolean>((resolve, reject) => {

      reader.onload = (e: any) => {
        try {

          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: WorkBook = read(binarystr, { type: 'binary' });
          this.productData = wb;

        } catch (error) {
          reject(error);
        } finally {
          resolve(true);
        }

      };
    });

  }

  onRunJob($event) {
    this.storageSvc.getPresignUrl('/test-sources/productLaunchWDW.xlsx', PresignAction.PUT)
      .pipe(
        concatMap(rsp => {
          return this.storageSvc.uploadWithPresignUrl(rsp, this.productData);
        }),
        concatMap(rsp => {
          return this.validationSvc.triggerProductJob();
        }),
      ).subscribe();
  }

}


