<!--The content below is only a placeholder and can be replaced.-->
<app-global-header #header></app-global-header>
<app-breadcrumbs #breadcrumbs></app-breadcrumbs>

<app-myid-session-modal
    #myIdSessionModal
>

</app-myid-session-modal>

<wdpr-activity-indicator-modal
  #WdprActivityModal
  [attr.open]="showActivityIndicator && myIdSessionModal._characterErrorModal?.hidden"
  disable-close-on-escape
  disable-close-on-background
  title="Please wait"
  [attr.subtitle]="activityIndicatorReason"
></wdpr-activity-indicator-modal>

<router-outlet></router-outlet>
