import { Component } from '@angular/core';

@Component({
  selector: 'app-distributor-page',
  templateUrl: './distributor-page.component.html',
  styleUrls: ['./distributor-page.component.scss']
})
export class DistributorPageComponent {

}
