import { Injectable } from '@angular/core';

/**
 * Export a reference of the global native browser window object.
 */
@Injectable({
  providedIn: 'root'
})
export class WindowRefService {
  get nativeWindow(): any {
    return window;
  }
}
