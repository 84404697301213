import { Component, OnInit } from '@angular/core';
import { CardGridConfig } from '@interfaces/card-grid-config';

@Component({
  selector: 'app-validations-page',
  templateUrl: './validations-page.component.html',
  styleUrls: ['./validations-page.component.scss']
})
export class ValidationsPageComponent implements OnInit {

  cards: CardGridConfig[] = [];

  ngOnInit(): void {
    this.cards = [
      {
        title: 'Product',
        icon: 'di-tickets',
        url: '/web/validations/products',
        desc: 'Execute a series of platform tests that verifies the existences of expected ticket codes/skus.'
      }
    ];
  }

}
