<div class="card-grid">
  <span *ngFor="let channelMgr of (channelManagers$ | async)" class="card-wrapper">
    <wdpr-card routerLink="/web/channel-managers/{{channelMgr.name}}">
      <div slot="card-content">
        <div class="title row">
          {{channelMgr.name}}
        </div>
        <div class="row product-types">
          <span class="capability">Product Types: </span>
          <span class="label" *ngFor="let productType of channelMgr.productTypes">
            <wdpr-status-label
              kind="success"
              [attr.text]="productType.name.code"
            ></wdpr-status-label>
          </span>
        </div>
        <div class="row property-brands">
          <span class="capability">Brands: </span>
          <span class="label" *ngFor="let propertyBrand of channelMgr.propertyBrands">
            <wdpr-status-label
              kind="success"
              [attr.text]="propertyBrand.code"
            >
            </wdpr-status-label>
          </span>
        </div>
      </div>
    </wdpr-card>
  </span>
</div>
