<!-- Use the component -->
<dprd-translate [attr.api-list]="apiList" #myDprdTranslate></dprd-translate>
<wdpr-error-panel #errorElement class="error-403" status-code="403"></wdpr-error-panel>
<div class="informational-block">
  <wdpr-informational-message
    #WdprInformationalMessage
    type="inline"
    (click)="redirectToSupportChannel()"
    message="If you feel this is in error and need additional support please reach out at"
    link-text="#studio-aristicoders"
    show="true">
  </wdpr-informational-message>
</div>
