/**
 * Refer to https://raportal.wdprapps.disney.com/web-components-catalog/kingswell/error-panel
 * for examples of which character to use and potential messaging.
 */
export enum ErrorCharacterType {
    UNAUTHORIZED = 403,
    NOT_FOUND = 404,
    SERVER_ERROR = 500,
    SESSION_EXPIRED = 401
}
