import { ConfigService } from '@services/config/config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationsService {

  private configs: object;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private logger: LoggerService
  ) {
    this.configs = this.configService.get();
  }

  public triggerProductJob(): Observable<object> {

    return this.httpClient.post<object>(
      `${this.configs['WEB_API_BASE_URL']}/validations/product`, {}
    );

  }

}
