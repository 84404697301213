<div class="container">
<div class="comp1"><app-file-upload-input class="width" id="fileUpload" (uploadFile)="uploadFiles($event)" [fileExtensions]="fileExtentions"/></div>
<div class="comp2"><wdpr-button class="button"  #runAllBtn (click)="runFiles()" theme="secondary" [disabled]="data===undefined">
  <i class="di di-media-play-button"></i>Run All
</wdpr-button>
</div>
</div>
<app-flat-data-table
  #dataTable
  [config]="dataTableConfig"
></app-flat-data-table>




