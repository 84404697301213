import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  styleUrls: ['./file-upload-input.component.scss']
})
export class FileUploadInputComponent implements OnInit {

  errorMessage: string[] = [];

  @ViewChild('fileUploadInfoSelect') _fileUploadInfoSelect: ElementRef;
  showFileUploadClearBtn = undefined; // RA Component doesn't actually check for boolean, so "false" is undefined
  fileUploadSelectText = "";

  @ViewChild('fileDialog') _fileInput: ElementRef;
  document: File;

  @Input() fileExtensions: string;
  @Input() defaultLabel: string;
  private _fileUploadSelectDefaultText = 'Please select a file to upload';

  @Output() uploadFile = new EventEmitter();
  @Output() clear = new EventEmitter();


  ngOnInit(): void {
    this.fileUploadSelectText = this.defaultLabel ? (this.defaultLabel) : (this._fileUploadSelectDefaultText);
  }

  clearFile() {
    // Reset Loaded File
    this.fileUploadSelectText = this._fileUploadSelectDefaultText;
    this.showFileUploadClearBtn = undefined;
    this.document = undefined;
    this._fileInput.nativeElement.value = '';
    this.clear.emit();
  }

  onFileChange($event: Event) {
    this.errorMessage = [];
    const eventInput = $event.target as HTMLInputElement;
    const files = eventInput.files;
    this.document = files[0];
    this.fileUploadSelectText = this.document.name;
    this.showFileUploadClearBtn = true;
    const splitStringArray = this.document.name.split(".");

    if (this.fileExtensions.includes(splitStringArray[1])) {
      this.uploadFile.emit(this.document);
    } else {
      this.errorMessage = ["Please upload a file with either of the following formats : " + this.fileExtensions];
    }
  }

}
