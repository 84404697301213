import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MyIdService } from "@services/myid/myid.service";
import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { RouteListenerService } from '@services/router-changes/route-listener.service';

@Component({
  selector: 'app-oidc-redirector',
  templateUrl: './oidc-redirector.component.html'
})
export class OidcRedirectorComponent implements AfterViewInit, OnDestroy {

  constructor(
    private myIdService: MyIdService,
    private router: Router,
    private activityIndicator: ActivityIndicatorService,
    private routeListenerService: RouteListenerService
  ) {
  }

  ngAfterViewInit(): void {

    this.activityIndicator.show();

    const session = this.myIdService.processLoginOrRestoreSession();

    if (!session.valid) {

      if(this.routeListenerService.getPreviousUrl() === '/web/login') {

        this.myIdService.redirectToMyID('/web/home');

      } else {

        // Angular takes a moment for navigation events to finish up
        const previousUrl = this.routeListenerService.getPreviousUrl();
        this.myIdService.redirectToMyID(previousUrl ? (previousUrl) : ('/web/home'));

      }

    } else {
      // Route if somehow we are authorized and made it to this component, or if we hard-navigated to login with a valid session
      // TODO: Disable for now, need to address how sessions are expired
      // if(this.routeListenerService.getPreviousUrl() === '/web/home' || !this.routeListenerService.getPreviousUrl()) {
      //   this.router.navigate(['/web/home']);
      // }
    }

  }

  ngOnDestroy(): void {

    this.activityIndicator.hide();

  }

}
