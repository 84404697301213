import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ActivityIndicatorService {

  private _showActivityIndicator = new BehaviorSubject<boolean>(false);
  private _activityIndicatorReason = new BehaviorSubject<string>(null);

  public showActivityIndicator$: Observable<boolean> = this._showActivityIndicator.asObservable();
  public activityIndicatorReason$: Observable<string> = this._activityIndicatorReason.asObservable();

  /**
   * Triggers the reveal of the activity indicator.
   * @param message Reason to show to end-user for the activity.
   */
  show(message = "") {
    this._showActivityIndicator.next(true);
    this._activityIndicatorReason.next(message);
  }

  /**
   * Triggers the activity indicator to be hidden.
   */
  hide() {
    this._showActivityIndicator.next(false);
    this._activityIndicatorReason.next(null);
  }

}
