import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FlatDataTableComponent } from '@core/components/flat-data-table/flat-data-table.component';
import { DataTableRow } from '@interfaces/data-table-row';
import { FlatDataTable } from '@interfaces/flat-data-table';
import { ChannelManager } from '@interfaces/ttc/channel-managers';
import { DateTimeSpanWindows, RatePlanCode } from '@interfaces/ttc/sync-room-avail-request';

import { ActivityIndicatorService } from '@services/activity-indicator/activity-indicator.service';
import { ProductType } from '@services/trade-product/product-type';
import { TradeInventoryService } from '@services/trade-inventory/trade-inventory.service';
import { TradeProductService } from '@services/trade-product/trade-product.service';
import { TradeUtilityService } from '@services/trade-utility/trade-utility.service';
import '@wdpr/datepicker';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-sync-packages',
  templateUrl: './sync-packages-page.component.html',
  styleUrls: ['./sync-packages-page.component.scss']
})
export class SyncPackagesPageComponent implements OnInit{
  title = "Sync - Package";

  readonly JSON = JSON;

  @ViewChild('cmSelectInput') cmSelectInput : ElementRef;
  channelManagerSelected: string;
  channelManagerSelectOptions: string[] = [];
  channelManagers: ChannelManager[] = [];

  @ViewChild('destinationSelectInput') destinationSelectInput: ElementRef;
  destinationSelectOptions: string[] = [];
  destData: string[];
  destSelected = "";

  @ViewChild('dataTable') dataTable: FlatDataTableComponent;
  dataTableConfig: FlatDataTable;
  datum: Promise<DataTableRow[]>;
  rawTableDatum: any[] = [];

  @ViewChild('startDateInput') startDateInput : ElementRef;
  startDateSelected: string;

  @ViewChild('endDateInput') endDateInput : ElementRef;
  endDateSelected: string;

  @ViewChild('failureToast') failure_Toast: ElementRef;
  @ViewChild('successToast') success_Toast: ElementRef;

  rateplanCodes: RatePlanCode[] = [];
  dateTimeSpanWindows: DateTimeSpanWindows[] = [];

  constructor(
    private tus: TradeUtilityService,
    private tps: TradeProductService,
    private tis: TradeInventoryService,
    private activityService: ActivityIndicatorService,
    private logger: LoggerService
  ) {
  }

  ngOnInit(): void {

    this.populateChannelManagers();
    this.initializeDataTable();

  }

  private initializeDataTable() {
    this.dataTableConfig = {
      columns: [
        {
          label: 'Package Code',
          field: 'ratePlanCode',
          sortable: true
        },
        {
          label: 'Onload Date',
          field: 'timestamp',
          sortable: true
        },
        {
          label: 'Expired',
          field: 'expired',
          type: 'status'
        }
      ],
      displayGrid: false,
      enableEditableRows: false,
      enableLazyScroll: true,
      enableQuickFilter: true,
      enableSelection: true,
      lockManualSelection: false,
      primaryKey: 'rowId',
      size: 'sm'
    };
  }

  private populateDataTable() {
    this.datum = Promise.resolve(this.rawTableDatum);
  }

  private populateChannelManagers() {

    this.activityService.show("Loading Channel Managers...");

    firstValueFrom(this.tus.getChannelManagers()).then((cmResponse) => {

      this.channelManagers = cmResponse;
      this.channelManagerSelectOptions = this.channelManagers.map(item => item.name);

    }).catch(reason => {
      this.logger.error('', { contextMsg: 'Failed to retrieve channel managers', reason });
    }).finally(() => {
      this.activityService.hide();
    });

  }

  private populateDestinations() {

    const cm = this.channelManagers.find((cm) => {
      return cm.name === this.channelManagerSelected;
    });

    if(cm) {
      this.destinationSelectOptions = cm.propertyBrands.map((propertyBrand) => {
        return propertyBrand.code;
      });
    }

  }


  onSelectChannelManager($event) {
    this.rawTableDatum = [];
    this.channelManagerSelected = $event.detail.value;
    this.populateDestinations();
    if(this.destSelected.length > 0) {
      this.destSelected = "";
      this.destinationSelectInput.nativeElement.value = "";
    }
  }

  onSelectDestination($event) {
    this.destSelected = $event.detail.value;
    this.populateProducts();
  }

  populateProducts() {
    this.activityService.show("Loading Rate Plan Codes...");

    firstValueFrom(this.tps.getProducts(this.channelManagerSelected, this.destSelected, ProductType.ROOM_PACKAGE)).then((productsResponse) => {

      this.rawTableDatum = productsResponse.map((item, index) => {

        const rowItem: DataTableRow = {
          rowId: index,
          ratePlanCode: item.ratePlan.ratePlanCode.code,
          timestamp: item.timestamp,
          expired: item.expired
        };

        return rowItem;

      });
    }).catch(reason => {
      this.logger.error('', { contextMsg: 'Failed to retrieve Rate Plan Codes', reason });
    }).finally(() => {
      this.populateDataTable();
      this.activityService.hide();
    });
  }

  onSyncClicked(){

    this.rateplanCodes = [] ;
    this.dateTimeSpanWindows = [] ;

    this.activityService.show();

    const selectedData = this.dataTable.getSelectedData();
    this.dateTimeSpanWindows.push({ type:"USAGE",start:this.startDateSelected,end:this.endDateSelected });

    if (this.rawTableDatum.length != selectedData.length) {
      selectedData.map((row) => {
        this.rateplanCodes.push({ value:row.ratePlanCode });
      });
    }

    firstValueFrom(this.tis.syncChannelManager(this.rateplanCodes,this.channelManagerSelected,this.destSelected ,this.dateTimeSpanWindows)).then(() => {

      this.successToast();

    }).catch(reason => {

      console.log(reason);
      this.logger.error('', { contextMsg: 'Failed to perform sync request chain, impartial results possible', reason });
      this.failureToast();

    }).finally(() => {

      this.activityService.hide();

    });

  }

  saveSelecteStartDate(selectedDate) {
    this.startDateSelected = selectedDate;
  }
  saveSelectedEndDate(selectedDate) {
    this.endDateSelected = selectedDate;

  }

  successToast() {
    this.success_Toast.nativeElement.open();
  }

  failureToast() {
    this.failure_Toast.nativeElement.open();
  }

  computeDateInTheFuture(daysNumber) {
    if (this.startDateSelected != null) {

      const date = new Date(this.startDateSelected);
      date.setDate(date.getDate() + daysNumber );

      return date.toISOString().split('T')[0];
    }
  }

  enableSync(){
    return (this.startDateSelected != null && this.endDateSelected != null && this.dataTable.getSelectedData().length > 0);
  }

}
