import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { WindowRefService } from '@services/window-ref/window-ref.service';

@Component({
  selector: 'app-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss']
})
export class UnauthorizedPageComponent implements AfterViewInit {

  apiList = JSON.stringify([{ 'uri': 'assets/i18n/error-translations.json' }]);

  @ViewChild('myDprdTranslate') translateElement: ElementRef;
  @ViewChild('errorElement') errorElement: ElementRef;

  constructor(
    private windowRef: WindowRefService
  ) {
  }

  ngAfterViewInit(): void {
    this.translateElement.nativeElement.loadContents().then(content => {
      this.errorElement.nativeElement['content'] = content;
    });
  }

  redirectToSupportChannel() {
    this.windowRef.nativeWindow.open('https://slack.com/app_redirect?channel=studio-aristocoders', '_blank');
  }

}
