<div class="upload-wrapper">

  <input #fileDialog
         type="file"
         id="fileInput"
         class="hidden-file-input"
         [attr.accept]="fileExtensions"
         (change)="onFileChange($event)"
  >

  <wdpr-input
    #fileUploadInfoSelect
    type="text"
    [attr.value]="fileUploadSelectText"
    [attr.clearbutton]=showFileUploadClearBtn
    (icon-button-click)="clearFile()"
    readonly=true
  ></wdpr-input>

  <wdpr-button
    (click)="_fileInput.nativeElement.click()"
    class="select" theme="primary"
  >
    <i class="di di-upload"></i>Select File
  </wdpr-button>

</div>
<wdpr-messages class="error-message" data-testid="message1" [values]="errorMessage"></wdpr-messages>
